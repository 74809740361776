<template>
    <li :class="'navigation-topbar-mobile__flyout-item navigation-topbar-mobile__flyout-item--'+ item.index">
        <router-link v-if="internalLink(item)" class="navigation-topbar-mobile__flyout-link" :to="{ path:getLinkPath(item) }">
            <span @click="linkClicked" class="navigation-topbar-mobile__flyout-link-title ">{{ item.pagename }}</span>
            <span v-if="submenue.length > 0" @click.prevent="$store.commit('setMobileNavigationParent', item.objvalueid)" class="navigation-topbar-mobile__flyout-icon navigation-topbar-mobile__flyout-icon--right icon icon--arrow-right-thin"></span>
        </router-link>
        <a v-if="submenue.length < 1 && !internalLink(item)" class="navigation-topbar-mobile__flyout-link" target="_blank" referrerpolicy="no-referrer" :href="item.link">
            <span class="navigation-topbar-mobile__flyout-link-title ">{{ item.pagename }}</span>
            <span class="navigation-topbar-mobile__flyout-icon navigation-topbar-mobile__flyout-icon--right icon icon--arrow-right-thin"></span>
        </a>
    </li>
</template>

<script>
export default({
    name: "NavItemMobile",
    props: ['item'],
    data() {
        return {
            subNavToggled: false
        }
    },
    computed: {
        submenue() {
            return this.$store.getters.navitems( this.item.objvalueid )
        }
    },
    methods: {
        internalLink(item) {
            if (!this.item.hasOwnProperty('link')) {
                return true
            }
            const fulluri = /^http/
            return item.link !== false && typeof item.link === 'string' && !fulluri.test(item.link)
        },
        getLinkPath(item) {
            if (item.objvalueid > -1) {
                return this.$store.getters.linkToPage(item.objvalueid)
            } else {
                return this.item.link
            }
        },
        linkClicked() {
            this.$emit('linkklicked')
        }
    }
});
</script>

<style scoped lang="scss">
    @use "sass:math";
    @import "../../style/just_variables";

    a {
        display: block;
        padding:(math.div($grid-gutter-width, 2));
        border-bottom:1px solid #eee;
    }

    li:first-child a {
        border-top:1px solid #eee;
    }
</style>