<template>
    <div id="splash-wrap">
        <div v-if="!showEventSplash" id="splash-logo" :class="showLoginForm ? 'splash-2-3' : 'splash-1'">
            <Errors v-if="this.$store.getters.errors.length > 0" />
            <span class="splash-logo"></span>
        </div>
        <form @submit.prevent="credentialSubmit" id="splash-login" v-if="showLoginForm && !showEventSplash && $store.state.isMobileDevice" class="splash-1-3">
            <section>
                <div class="row">
                    <div class="col">
                        <h2 class="-pui-text-size-2-thin pui-text--align-center pui-text--wrap pui-pb--24">
                            Login
                        </h2>
                    </div>
                </div>

              <div class="form-group">
                <label for="mailaddress">{{ $store.getters.tText('mailaddress') }}</label>
                <input class="form-control" id="mailaddress" type="Email" v-model="mailAddress"  >
              </div>

              <div class="form-group">
                <label for="logincode">{{ $store.getters.tText('logincode') }}</label>
                <input class="form-control" id="logincode"  type="Password" v-model="loginCode"  >
              </div>

                <div class="row">
                    <div class="col">
                        <button class="btn btn-primary btn btn-block" type="submit">
                            {{ $store.getters.tText('enter') }}
                        </button>
                    </div>
                </div>
            </section>
        </form>
        <MobileDevicePrompt v-if="!$store.state.isMobileDevice" />
        <transition name="fade">
        <div v-if="showEventSplash" class="eventSplash" :style="'background-image:url('+ showEventSplash +');'"></div>
        </transition>
    </div>
</template>

<script>
import FormElement from '@/components/FormElement.vue'
import MobileDevicePrompt from '@/components/MobileDevicePrompt.vue'
import Errors from '@/components/Errors.vue'
import appname from '@/appname'

export default({
    name: "Splash",
    components: { FormElement, Errors, MobileDevicePrompt },
    data() {
        return {
            mailAddress: '',
            loginCode: '',
            appname
        }
    },
    created() {
        this.$watch('$store.state.searchedCredentials', this.testCredentials)
    },
    computed: {
        showLoginForm() {
            return this.$store.state.searchedCredentials && this.$store.state.isOnline && this.$store.state.credentials.jwt === null
        },
        showEventSplash() {
            if (!this.$store.state.searchedCredentials) {
                return false
            } else {
                const eventData = this.$store.getters.eventData
                if (eventData.hasOwnProperty('splash_image') && typeof eventData.splash_image === 'string' && eventData.splash_image !== '') {
                    return eventData.splash_image
                } else {
                    return false
                }
            }
        }
    },
    mounted() {
        const vm = this
        vm.setSplashSize()
        window.addEventListener('resize', vm.setSplashSize)
    },
    methods: {
        setSplashSize() {
            const h = Math.max(document.documentElement.clientHeight, window.innerHeight || 0)
            this.$el.style.setProperty('--splash-height', h +'px')
            this.$el.style.setProperty('--half-height', h +'px')
            this.$el.style.setProperty('--two-third-height', Math.floor((h / 2)) +'px')
            this.$el.style.setProperty('--one-third-height', Math.floor((h / 2)) +'px')
        },
        credentialSubmit() {
            this.$store.dispatch('loginUser', {mailaddress:this.mailAddress, logincode:this.loginCode})
        },
        testCredentials(wastested) {
            if (wastested) {
                if (
                    this.$store.state.credentials.jwt !== null &&
                    typeof this.$store.state.credentials.lastroute === 'string'
                ) {
                    this.$store.dispatch('readEventData')
                }
            }
        }
    },
    beforeUnmount() {
        const vm = this
        window.removeEventListener('resize', vm.setSplashSize)
    }
});
</script>

<style scoped lang="scss">
    #splash-wrap {
        --splash-height:100px;
        --half-height:100px;
        --two-third-height:100px;
        --one-third-height:0px;
        
        background-color: #fff;
        
        position: relative;
        height:var(--splash-height);
        max-height:100%;
        
        & > div {
            width:100%;
            height:var(--two-third-height);
            display:flex;
            justify-content:center;
            align-items:center;
            align-content:center;
            
            &.splash-1 {
                height:var(--half-height);
            }
            
            &.splash-1-3 {
                height:var(--one-third-height);
            }
            
            &.splash-2-3 {
                height:var(--two-third-height);
            }
        }
        
        .splash-logo {
            position: relative;
            display: block;
            height:350px;
            width:350px;
            background: transparent url("~@/assets/svg/hagebau_Logo_RGB.svg") no-repeat;
            background-position: center;
            background-size: contain;
        }
        
        .eventSplash {
            background-position: center;
            background-repeat: no-repeat;
            background-size: cover;
            position: absolute;
            top:0;
            left:0;
            width:100%;
            height:var(--splash-height);
        }

        .fade-enter-active, .fade-leave-active {
            transition: opacity .5s;
        }
        .fade-enter, .fade-leave-to {
            opacity: 0;
        }
    }
</style>