import {createStore} from 'vuex';
import db from './db'
import crud, { setCrudStore, tokenTest } from './crud'
import router from './router'
import staticTexts from "./staticTexts"
import staticTextsApp from "@/staticTexts"
import _ from 'lodash'
import appname from '@/appname'
import { toRaw } from 'vue';
import moment from 'moment-timezone'

const escapeRegExp = (s) => {
  return s.replace(/[-/\\^$*+?.()|[\]{}]/g, '\\$&')
}

const prod = process.env.NODE_ENV === "production";

const store = createStore({
  state: {
    launchMoment: moment(),
    codeVersion: moment('2020-03-02T12:30:00+01:00'),
    tick: moment(),
    tickInterval: null,

    errors:[],
    networkErrors:[],
    quotaExceeded:false,
    appErrors:[],
    isOnline:false,
    isVisible:true,
    isMobileDevice:true,
    isWKWebview: !prod,
    connection: {
      networkSpeedKBs:null,
      isgzipped:false
    },
    searchedCredentials:false,
    language:'de',
    languagesAvailable: ['de'],
    events: [],
    eventid: null,
    debugmode: false,
    credentials: {
      objvalueid: null,
      groupid: null,
      firstname: null,
      lastname: null,
      salutation: null,
      title: null,
      language: null,
      lastroute: null,
      logintime: null,
      testuser: false,
      barcode: [],
      jwt: null
    },
    timezone: '',
    pages:[],
    locations:[],
    feedbackquestions:null,
    feedbackanswers:[],
    readPages:false,
    weatherData: null,
    mobileNavDepth: 0,
    texts: {...staticTexts, ...staticTextsApp},
    pagesLoadTriggered:[],
    eventdataLoading:false
  },
  mutations: {
    goOnline(state) { state.isOnline = true },
    goOffline(state) { state.isOnline = false },

    clearErrors(state) { state.errors = [] },
    clearDebugErrors(state) {
      state.networkErrors = []
      state.appErrors = []
    },
    appendError(state, pl) { state.errors = [ ...state.errors, pl ] },

    tick(state) {

      if (!state.isOnline && navigator.onLine) {
        state.isOnline = true
      }

      if (state.timezone !== '') {
        state.tick = moment().tz(state.timezone)
      } else {
        state.tick = moment()
      }
    },

    setDebugMode(state, newmode) {
      state.debugmode = newmode
    },

    logNetworkError(state, logdata) {
      if (state.credentials.testuser) {
        state.networkErrors = [
          ...state.networkErrors,
          {
            ...logdata,
            index: state.networkErrors.length
          }
        ]
      }
    },

    setNetworkSpeed(state, speed) {
      state.connection.networkSpeedKBs = speed
    },
    setIfGZipped(state, pl) {
      state.connection.isgzipped = pl
    },

    logAppError(state, logdata) {
      if (!db.isOpen()) {
        db.open()
      }

      if ("error" in logdata) {
        if ("inner" in logdata.error) {
          if ("name" in logdata.error.inner) {
            logdata.reason = logdata.error.inner.name
          } else {
            logdata.reason = logdata.error.inner
          }
        } else {
          logdata.reason = logdata.error.name
        }
        delete logdata.error
      }

      const QuotaError = /QuotaExceeded/

      if (QuotaError.test(logdata.reason) && state.quotaExceeded === false) {
        state.quotaExceeded = true
        // Seiten Offline Modus

        if (state.isOnline) {
          crud('logStorageError', {})
              .catch(err => { console.error(err) })
        }
      }

      if (state.credentials.testuser) {
        state.appErrors = [
          ...state.appErrors,
          {
            ...logdata,
            index: state.appErrors.length
          }
        ]
      }
    },

    testIfMobileDevice(state) {
        state.isMobileDevice = /(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|ipad|iris|kindle|Android|Silk|lge |maemo|midp|mmp|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows (ce|phone)|xda|xiino/i.test(navigator.userAgent)
            || /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s\-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|\-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw\-(n|u)|c55\/|capi|ccwa|cdm\-|cell|chtm|cldc|cmd\-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc\-s|devi|dica|dmob|do(c|p)o|ds(12|\-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(\-|_)|g1 u|g560|gene|gf\-5|g\-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd\-(m|p|t)|hei\-|hi(pt|ta)|hp( i|ip)|hs\-c|ht(c(\-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i\-(20|go|ma)|i230|iac( |\-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc\-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|\-[a-w])|libw|lynx|m1\-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m\-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(\-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)\-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|\-([1-8]|c))|phil|pire|pl(ay|uc)|pn\-2|po(ck|rt|se)|prox|psio|pt\-g|qa\-a|qc(07|12|21|32|60|\-[2-7]|i\-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h\-|oo|p\-)|sdk\/|se(c(\-|0|1)|47|mc|nd|ri)|sgh\-|shar|sie(\-|m)|sk\-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h\-|v\-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl\-|tdg\-|tel(i|m)|tim\-|t\-mo|to(pl|sh)|ts(70|m\-|m3|m5)|tx\-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|\-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(\-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas\-|your|zeto|zte\-/i.test(navigator.userAgent.substr(0,4))

        if (navigator.platform.substr(0,2) === 'iP'){
          //iOS (iPhone, iPod or iPad)
          var lte9 = /constructor/i.test(window.HTMLElement);
          var nav = window.navigator, ua = nav.userAgent, idb = !!window.indexedDB;
          if (ua.indexOf('Safari') !== -1 && ua.indexOf('Version') !== -1 && !nav.standalone){
            //Safari (WKWebView/Nitro since 6+)
          } else if ((!idb && lte9) || !window.statusbar.visible) {
            //UIWebView
          } else if ((window.webkit && window.webkit.messageHandlers) || !lte9 || idb){
            state.isWKWebview = true
          }
        }
    },

    updateCredentials(state, credentials) {
      if (
          credentials.hasOwnProperty('language') && typeof credentials.language === 'string' &&
          (credentials.language === 'de' || credentials.language === 'en') &&
          state.language !== credentials.language
      ) {
        state.language = credentials.language
      }
      state.credentials = {
        ...state.credentials,
        ...credentials
      }
    },

    gotoEvent(state, pl) {
      const tevent = _.find(state.events, { objvalueid:pl })
      if (tevent) {

        state.timezone = tevent.timezone
        state.eventid = pl

        const waitForSplash =
            tevent.hasOwnProperty('splash_image') &&
            typeof tevent.splash_image === 'string' &&
            tevent.splash_image !== ''
                ? 2500 : 1

        window.setTimeout(() => {
          if (typeof state.credentials.lastroute === 'string') {
            if (router.currentRoute.value.path !== state.credentials.lastroute) {
              router.push({ path: state.credentials.lastroute })
            }
          } else {
            router.push({ path: '/event/'+ state.eventid})
          }
        }, waitForSplash)

      }
    },

    gotoOverviewEvents(state, pl) {
      router.push({ path: '/events'}).catch(()=>{});
    },

    setUserData(state, pl) {
      state.searchedCredentials = true
    },

    setEventData(state, pl) {
      state.events = [...pl.data]
      if (!pl.hasOwnProperty('dontupdate') || pl.dontupdate !== true) {
        const eventids = []
        pl.data.forEach(ev => {
          eventids.push(ev.objvalueid)
        })
          const rawdata= toRaw(pl.data);
        db.event.bulkPut(rawdata)
            .catch(err => {
              store.commit('logAppError', {
                error: err,
                time: new Date()
              })
              console.error('error bulkPut setEventData')
              console.error(err)
            })
        db.event
            .where('objvalueid').noneOf(eventids)
            .delete()
            .then(() => {

            })
            .catch(err => {
              store.commit('logAppError', {
                error: err,
                time: new Date()
              })

              console.error(err)
            })
      }
    },

    queueUpdate(state) {
      const cevent = _.find(state.events, ev => ev.objvalueid === parseInt(state.eventid,10))
      cevent.last_fetch = moment().format()
      if (!state.eventdataLoading) {
        state.events = [...state.events]
        const eventids = []
        state.events.forEach(ev => {
          eventids.push(ev.objvalueid)
        })

        let data = JSON.parse(JSON.stringify(state.events))
        db.event.bulkPut(data)
            .catch(err => {
              store.commit('logAppError', {
                error: err,
                time: new Date()
              })
              console.error('error bulkPut queueUpdate')
              console.error(err)
            })
        db.event
          .where('objvalueid').noneOf(eventids)
          .delete()
            .then(() => {
            })
            .catch(err => {
              store.commit('logAppError', {
                error: err,
                time: new Date()
              })

              console.error(err)
            })
      }
    },

    ResetEvent(state) {
          state.eventid = null
    },

    setPages(state, pl) {
      state.pages = [...pl]
      if (state.pagesLoadTriggered.length === 0) {
        // Erst im Localstorage anpassen, wenn alles geladen ist.

        if (pl && pl.length > 0) {
          pl.forEach(p => {
            db.page
                .get({objvalueid: p.objvalueid})
                .then((oldPage) => {
                  if (p.force_save_offline || (!state.quotaExceeded && !state.isWKWebview)) {
                      const rawp = toRaw(p);
                    db.page.put(rawp)
                        .catch(err => {
                          store.commit('logAppError', {
                            error: err,
                            time: new Date()
                          })

                          console.error(err)
                        })
                  } else {
                    const useLimitedOpts = {
                      objvalueid: p.objvalueid,
                      eventid: p.eventid,
                      pagename_en: p.pagename_en,
                      pagename_de: p.pagename_de,
                      content_de: null,
                      content_en: null,
                      parentid: p.parentid,
                      showstop: p.showstop,
                      sortpos: p.sortpos,
                      icon: p.icon,
                      timezone: p.timezone,
                      rptype: p.rptype,
                      content_update: p.content_update,
                      meta: null,
                      feedback_answered: p.feedback_answered,
                      is_kachel: p.is_kachel,
                      kachel_color: p.kachel_color,
                      kachel_image: p.kachel_image,
                      kachel_image_checksum: p.kachel_image_checksum,
                      kachel_pos: p.kachel_pos,
                      kachel_size: p.kachel_size,
                      searchable: p.searchable
                    }
                      const rawuseLimitedOpts = toRaw(useLimitedOpts);
                    db.page.put(rawuseLimitedOpts)
                        .catch(err => {
                          store.commit('logAppError', {
                            error: err,
                            time: new Date()
                          })

                          console.error(err)
                        })
                  }
                })
          })
        }
      }

    },

    setPageKachelImage(state, pl) {
      const cpage = _.find(state.pages, p => p.objvalueid === pl.pageid)
      if (cpage) {
        if (pl.kachel_image_checksum || pl.kachel_image_checksum !== cpage.kachel_image_checksum) {
          cpage.kachel_image = pl.kachel_image
          cpage.kachel_image_checksum = pl.kachel_image_checksum
          state.pages = [...state.pages]

          db.page
              .get({objvalueid: pl.pageid})
              .then((oldPage) => {
                if (oldPage) {
                  oldPage.kachel_image = pl.kachel_image
                  oldPage.kachel_image_checksum = pl.kachel_image_checksum

                    const rawoldPage = toRaw(oldPage);
                  db.page.put(rawoldPage)
                      .catch(err => {
                        store.commit('logAppError', {
                          error: err,
                          time: new Date()
                        })

                        console.error(err)
                      })
                }
              })
        }
      }
    },

  setFeedbackQuestions(state, pl) {
      state.feedbackquestions = [ ...pl ]

      if (state.quotaExceeded) {
          return
      }

      db.feedbackquestion
          .where('eventid')
          .equals(state.eventid)
          .toArray()
          .then(ofbqs => {
              if (ofbqs) {
                  pl.forEach(nq => {
                      const oldQuestion = _.find(ofbqs, oq => oq.objvalueid === nq.objvalueid && oq.pid === nq.pid)
                      if (oldQuestion) {
                          oldQuestion.caption_de = nq.caption_de
                          if (nq.hasOwnProperty('rating_options_de')) {
                              oldQuestion.rating_options_de = nq.rating_options_de
                          }
                          oldQuestion.question_type = nq.question_type
                          oldQuestion.sortpos = nq.sortpos
                          oldQuestion.pid = nq.pid
                          oldQuestion.save()
                      } else {
                          const rawnq = toRaw(nq);
                          db.feedbackquestion.put(rawnq)
                              .catch(err => {
                                  store.commit('logAppError', {
                                      error: err,
                                      time: new Date()
                                  })

                                  console.error(err)
                              })
                      }
                  })
              } else {
                  const rawpl= toRaw(pl);
                  db.feedbackquestion.bulkPut(rawpl)
                      .catch(err => {
                          store.commit('logAppError', {
                              error: err,
                              time: new Date()
                          })
                          console.error('error bulkPut setFeedbackQuestions')
                          console.error(err)
                      })
              }
          })
  },

  unloadFeedbackQuestions(state) {
      if (!state.quotaExceeded) {
          state.feedbackquestions = null
      }
  },

  setFeedbackAnswer(state, pl) {
      const tFeedbackquestion = _.find(state.feedbackquestions, ofbq => ofbq.objvalueid === pl.fbq.objvalueid && ofbq.pid === pl.fbq.pid)
      if (tFeedbackquestion) {
          tFeedbackquestion.answer = pl.fba.answer

          const getAnswer = () => {
              if (state.quotaExceeded) {
                  return new Promise(resolve => {
                      resolve(_.find(state.feedbackanswers, sfba => sfba.objvalueid === pl.fbq.objvalueid && sfba.pid === pl.fbq.pid && sfba.eventid === state.eventid))
                  })
              } else {
                  return db.feedbackanswer
                      .where('[objvalueid+eventid+pid]')
                      .equals([pl.fbq.objvalueid, state.eventid, pl.fbq.pid])
                      .last()
              }
          }

          getAnswer()
              .then(oldAnswer => {
                  const storeOldAnswer = _.find(state.feedbackanswers, sfba => sfba.objvalueid === pl.fbq.objvalueid && sfba.pid === pl.fbq.pid && sfba.eventid === state.eventid)

                  if (oldAnswer) {
                      oldAnswer.answer = pl.fba.answer
                      if (!oldAnswer.hasOwnProperty('sent')) {
                          oldAnswer.sent = false
                      }
                      if (!oldAnswer.hasOwnProperty('allowed_to_send')) {
                          oldAnswer.allowed_to_send = false
                      }
                  } else {
                      oldAnswer = {
                          objvalueid: pl.fbq.objvalueid,
                          pid: pl.fbq.pid,
                          eventid: state.eventid,
                          answer: pl.fba.answer,
                          sent: false,
                          allowed_to_send: false
                      }
                  }

                  if (!storeOldAnswer) {
                      state.feedbackanswers.push(oldAnswer)
                  } else if (storeOldAnswer !== oldAnswer) {
                      storeOldAnswer.objvalueid = oldAnswer.objvalueid
                      storeOldAnswer.pid = oldAnswer.pid
                      storeOldAnswer.eventid = state.eventid
                      storeOldAnswer.answer = oldAnswer.answer
                      storeOldAnswer.sent = oldAnswer.sent
                      storeOldAnswer.allowed_to_send = oldAnswer.allowed_to_send
                  }

                  state.feedbackanswers = [...state.feedbackanswers]

                  const rawoldAnswer = toRaw(oldAnswer);
                  db.feedbackanswer.put(rawoldAnswer)
                      .catch(err => {
                          store.commit('logAppError', {
                              error: err,
                              time: new Date()
                          })

                          console.error(err)
                      })
              })
      }

      state.feedbackquestions = [...state.feedbackquestions]
  },

    setLocations(state, pl) {
      state.locations = [...pl]
      if (pl && pl.length > 0) {

        const rawpl= toRaw(pl);
        db.location.bulkPut(rawpl)
            .then(() => {})
            .catch(err => {
              store.commit('logAppError', {
                error: err,
                time: new Date()
              })
              console.error('error bulkPut setLocations')
              console.error(err)
            })
      }

    },

    setJWT(state, pl) {
      state.credentials.jwt = pl
      updateStoreUserdata()
    },

    updateLastRoute(state, route) {
      if (route !== '/' && route !== '/logout' && route !== '/logout/confirm' && (!state.eventid || route !== '/event/'+ state.eventid +'/logout')) {
        state.credentials.lastroute = appname !== 'hev' ? route : null
        updateStoreUserdata()
      }
    },

    setLanguage(state, pl) {
      if (state.languagesAvailable.length === 1) {
        state.language = _.head(state.languagesAvailable)
      } else {
        if (pl && typeof pl === 'string' && (pl === 'de' || pl === 'en')) {
          state.language = pl
        } else {
          const inDe = /de/i
          if (window.navigator.language && inDe.test(window.navigator.language)) {
            state.language = 'de'
          } else {
            state.language = 'en'
          }
        }
      }

      state.credentials.language = state.language

      updateStoreUserdata()
      moment.locale(state.language === 'de' ? 'de' : 'en-gb')
    },

    setWeatherData(state, pl) {
      let wD = null

      if (typeof pl !== 'undefined') {
        if (pl.hasOwnProperty('data') && typeof pl.data === 'string') {
          wD = JSON.parse(pl.data)
        } else {
          wD = pl.data
          db.weather
            .where('[lat+lon]')
            .equals([pl.lat, pl.lon])
            .last()
            .then(wdata => {
              if (typeof wdata === 'undefined') {
                db.weather.add({
                  lat: pl.lat,
                  lon: pl.lon,
                  data: JSON.stringify({ ...wD }),
                  age: new Date()
                })
              } else {
                wdata.data = JSON.stringify({ ...wD })
                wdata.age = new Date()
                wdata.save()
              }
            })
        }
        state.weatherData = { ...wD }
      }
    },

    setMobileNavigationParent(state, pl) {
      state.mobileNavDepth = parseInt(pl,10)
    },

    visibilityChanged(state) {
      switch (document.visibilityState) {
        case "visible":
          state.isVisible = true

          if (!db.isOpen()) {
            db.open()
          }

          if (state.tickInterval) {
            window.clearInterval(state.tickInterval)
            state.tickInterval = null
            if (state.timezone !== '') {
              state.tick = moment().tz(state.timezone)
            } else {
              state.tick = moment()
            }
          }

          state.tickInterval = window.setInterval(() => { store.commit('tick') } , 1000)

          break
        case "hidden":
          state.isVisible = false

          if (state.tickInterval) {
            window.clearInterval(state.tickInterval)
            state.tickInterval = null
          }
          break
        default: break
      }
    }
  },
  getters: {
    eventData: (state) => {
      const useLang = state.language === null || state.language !== 'de' ? 'en' : 'de'
      const useEvent = _.find(state.events, e => e.objvalueid === state.eventid)

      const resEvent = {
        objvalueid: null,
        guesteventid: null,
        name: null,
        timezone: null,
        startdate: null,
        startMoment: null,
        enddate: null,
        weather: {
          cityName:'',
          lat:0.0,
          lon:0.0,
          radius:0
        },
        map: {
          cityName:'',
          lat:0.0,
          lon:0.0,
          radius:0,
          minZoom:1,
          maxZoom: 16
        },
        colors: {
          event_color:null,
          event_color_contrast:null,
          color_kachel_default:null,
          color_kachel_default_text:null
        },
        splash_image: null,
        last_fetch: moment(),
        back_behavior: 1,
        placeholders: []
      }

      if (typeof useEvent !== 'undefined' && useEvent !== null) {
        if (router.currentRoute.value.params.hasOwnProperty('eventid') && parseInt(router.currentRoute.value.params.eventid,10) !== parseInt(state.eventid,10) ) {
          // Das aktuelle Event stimmt nicht mit dem ausgewählen überein.
          state.credentials.lastroute = null
          updateStoreUserdata()
          router.push('/')
        }

        resEvent.objvalueid = useEvent.objvalueid
        resEvent.guesteventid = useEvent.guesteventid
        resEvent.timezone = useEvent.timezone
        resEvent.startdate = useEvent.startdate
        resEvent.startMoment = (useEvent.startdate && useEvent.timezone) ? moment.tz(useEvent.startdate, useEvent.timezone) : null
        resEvent.enddate = useEvent.enddate
        if (useEvent.hasOwnProperty('event_color')) {
          resEvent.colors.event_color_contrast = useEvent.event_color_contrast
          resEvent.colors.event_color = useEvent.event_color
          resEvent.colors.color_kachel_default = useEvent.color_kachel_default
          resEvent.colors.color_kachel_default_text = useEvent.color_kachel_default_text
        }
        resEvent.weather.lat = useEvent.weather_lat
        resEvent.weather.lon = useEvent.weather_lon
        resEvent.weather.radius = useEvent.weather_radius

        resEvent.map.lat = useEvent.map_lat
        resEvent.map.lon = useEvent.map_lon
        resEvent.map.radius = useEvent.map_radius
        if (useEvent.hasOwnProperty('map_min_zoom')) {
          resEvent.map.minZoom = useEvent.map_min_zoom
        }
        if (useEvent.hasOwnProperty('map_max_zoom')) {
          resEvent.map.maxZoom = useEvent.map_max_zoom
        }

        resEvent.last_fetch = useEvent.last_fetch ? moment(useEvent.last_fetch) : moment()

        if (useEvent.hasOwnProperty('splash_image')) {
          resEvent.splash_image = useEvent.splash_image
        }
        if (useEvent.hasOwnProperty('feedback_answered')) {
          resEvent.feedback_answered = useEvent.feedback_answered
          if (resEvent.feedback_answered) {
            try {
              resEvent.feedback_answered = moment(resEvent.feedback_answered)
            } catch (e) {
              resEvent.feedback_answered = null
            }
          }
        }
        if (useEvent.hasOwnProperty('placeholders')) {
          resEvent.placeholders = useEvent.placeholders
        }

        if ((useLang === 'de' && useEvent.name_de !== null && useEvent.name_de !== '') || useEvent.name_en === null || useEvent.name_en === '') {
          resEvent.name = useEvent.name_de
        } else {
          resEvent.name = useEvent.name_en
        }
        if ((useLang === 'de' && useEvent.weather_cityname_de !== null && useEvent.weather_cityname_de !== '') || useEvent.weather_cityname_en === null || useEvent.weather_cityname_en === '') {
          resEvent.weather.cityName = useEvent.weather_cityname_de
        } else {
          resEvent.weather.cityName = useEvent.weather_cityname_en
        }
        if ((useLang === 'de' && useEvent.map_cityname_de !== null && useEvent.map_cityname_de !== '') || useEvent.map_cityname_en === null || useEvent.map_cityname_en === '') {
          resEvent.map.cityName = useEvent.map_cityname_de
        } else {
          resEvent.map.cityName = useEvent.map_cityname_en
        }
      }

      return resEvent
    },
    navitems: (state) => (parentid) => {
      if (parentid < 0) {
        return []
      }

      const useLang = state.language === null || state.language !== 'de' ? 'en' : 'de'

      const test_page_validity = (p) => {
        return true
      }

      let useNav = _.filter( _.orderBy(state.pages, ['sortpos'],['asc']) , p => p.parentid === parentid && test_page_validity(p))

      const navTree = []

      let navindex = 0


      if (parentid === 0) {

          if (state.events.length > 1) {
              navTree.push({
                  objvalueid: -1,
                  parentid: null,
                  icon: null,
                  pagename: 'Veranstaltungen',
                  link: '/events',
                  index: navindex++
              })
          }

          if (state.eventid) {
                navTree.push({
                  objvalueid: -1,
                  parentid: null,
                  icon: null,
                  pagename: 'Home',
                  link: '/event/'+ state.eventid,
                  index: navindex++
                })
          }
      }

        if (state.eventid) {
            useNav.forEach(nE => {
                const modEntry = {}
                modEntry.objvalueid = nE.objvalueid
                modEntry.parentid = nE.parentid
                modEntry.icon = nE.icon
                modEntry.pagename = (useLang === 'de' && nE.pagename_de !== null && nE.pagename_de !== '') || (nE.pagename_en === null || nE.pagename_en === '') ? nE.pagename_de : nE.pagename_en
                modEntry.index = navindex++

                navTree.push(modEntry)
            })
        }

      if (parentid === 0) {
      /*  if (state.credentials.testuser) {
          navTree.push({
            objvalueid: -1,
            parentid: null,
            icon: null,
            pagename: (useLang === 'de' ? 'Debug' : 'Debug'),
            link: (state.eventid ? '/event/'+ state.eventid:'') + '/debug',
            index: navindex++
          })
        }*/

        navTree.push({
          objvalueid: -1,
          parentid: null,
          icon: null,
          pagename: (useLang === 'de' ? 'Logout' : 'Log out'),
          link: '/logout',
          index: navindex++
        })
      }

      return navTree
    },
    kacheln: (state) => {
      const ks = []
      const useLang = state.language === null || state.language !== 'de' ? 'en' : 'de'
      const usePages = _.sortBy(_.filter(state.pages, p => p.is_kachel > 0 && parseInt(p.eventid,10) === parseInt(state.eventid,10)), ['kachel_pos'])

      if (usePages !== null) {
        usePages.forEach(p => {
          p.kachel_size = Math.ceil(p.kachel_size / 6) * 6
          ks.push({
            objvalueid: p.objvalueid,
            color: p.kachel_color,
            feedback_answered: p.feedback_answered,
            image: p.kachel_image,
            pos: p.kachel_pos,
            size: p.kachel_size,
            rptype: p.rptype,
            icon: p.icon,
            pagename: ((useLang === 'de' && p.pagename_de !== null && p.pagename_de !== '') || (p.pagename_en === null || p.pagename_en === '') ? p.pagename_de : p.pagename_en)
          })
        })
      }

      return _.map(ks, kachel => {
        if (kachel.size < 12) {
          // Große Kacheln nicht verkleinern, aber kleine vergrößern
          const kachel_i = _.findIndex(ks, kfi => kfi === kachel)
          const kachel_before = kachel_i > 0 ? ks[((kachel_i - 1))] : null
          const kachel_after = kachel_i < (ks.length - 1) ? ks[((kachel_i + 1))] : null

          if (kachel_before && kachel_before.size === 12 && (kachel_after === null || kachel_after.size === 12)) {
            // Letzte Kachel oder zwischen 2 12ern
            kachel.size = 12
          }
        }
        return kachel
      })
    },

    linkToEvent: (state) => (eventid) => {
      let link = ''
      link = '/event/'+ eventid
      return link
    },

    linkToPage: (state) => (pageid) => {
      let link = ''
      const tpage = _.find(state.pages, p => parseInt(p.objvalueid,10) === parseInt(pageid,10))
      if (!tpage) {
        return false
      }
      /*
        * 1	Bilder- Videogalerie
          2 QR-Code
          3	Karte
          4	Wetter
         10 Feedback
        */
      switch (tpage.rptype) {
        case 1:
          link = '/event/'+ tpage.eventid +'/c/'+ tpage.objvalueid +'/g'
          break
        case 2:
          link = '/event/'+ tpage.eventid +'/qrcode'
          break
        case 3:
          link = '/event/'+ tpage.eventid +'/map'
          break
        case 4:
          link = '/event/'+ tpage.eventid +'/weather'
          break
        case 10:
          link = '/event/'+ tpage.eventid +'/c/'+ tpage.objvalueid +'/f'
          break
        default:
          link = '/event/'+ tpage.eventid +'/c/'+ tpage.objvalueid
          break
      }
      return link
    },

    nextWeatherEntry: (state) => {
      if (state.tick === null || !state.weatherData || !state.weatherData.hasOwnProperty('list')) {
        return null
      }
      return _.find(state.weatherData.list, wD => {
        const dateMoment = moment.utc(wD.dt_txt.replace(/ /,'T'))
        return dateMoment.isAfter(state.tick)
      })
    },

    selectedPage: (state, getters) => (pageid) => {
      if (state.pages === null) {
        return null
      }

      if (pageid === 'map') {
        pageid = _.find(state.pages, op => parseInt(op.rptype,10) === 3)
        if (typeof pageid === 'undefined') {
          return null
        }
        pageid = pageid.objvalueid
      }

      if (pageid === 'qrcode') {
        pageid = _.find(state.pages, op => parseInt(op.rptype,10) === 2)
        if (typeof pageid === 'undefined') {
          return null
        }
        pageid = pageid.objvalueid
      }

        if (pageid === 'feedback') {
            pageid = _.find(state.pages, op => parseInt(op.rptype,10) === 10)
            if (typeof pageid === 'undefined') {
                return null
            }
            pageid = pageid.objvalueid
        }

      const useLang = state.language === null || state.language !== 'de' ? 'en' : 'de'
      const tpage = _.find(state.pages, op => parseInt(op.objvalueid,10) === parseInt(pageid, 10))
      if (tpage === null) {
        return null
      }
      const usePage = {...tpage}
      usePage.meta = (typeof usePage.meta === 'string' && usePage.meta !== '' ? JSON.parse(usePage.meta) : usePage.meta)
      usePage.pagename = useLang === 'de' && usePage.pagename_de !== '' && usePage.pagename_de !== null ? usePage.pagename_de : usePage.pagename_en

      if (usePage.content_de === '' || usePage.content_de === null) {
        // Wenn deutsch nicht vorliegt, immer englisch nehmen.
        usePage.content = usePage.content_en
      } else if (usePage.content_en === '' || usePage.content_en === null) {
        // Wenn englisch nicht vorliegt, nur deutsch nehemen, wenn deutsch ausgewählt wurde.
        usePage.content = useLang === 'de' ? usePage.content_de : ''
      } else {
        // Wenn beide Sprachen vorliegen, ausgewählte verwenden.
        usePage.content = useLang === 'de' ? usePage.content_de : usePage.content_en
      }

      if (getters.eventData.placeholders.length > 0) {
        getters.eventData.placeholders.forEach(pconf => {
          const pregex = new RegExp(escapeRegExp(pconf.ph))
          if (pregex.test(usePage.content)) {
            let useValue = useLang === 'de' ? pconf.pv.de : pconf.pv.en
            if (useValue === null) {
              // kein Wert für den Platzhalter

              let startOfPlaceholder = -1
              let startofrow = 0
              let endofrow = 0
              let foundlast_br = 0
              let possible_ends = []
              while ((startOfPlaceholder = startofrow = endofrow = usePage.content.indexOf(pconf.ph)) > -1) {
                endofrow += pconf.ph.length

                possible_ends = [
                  endofrow,
                  usePage.content.indexOf('<br',endofrow),
                  usePage.content.indexOf('</div',endofrow),
                  usePage.content.indexOf('</p',endofrow),
                  usePage.content.indexOf('</td',endofrow),
                ]

                _.remove(possible_ends, pe => pe < startOfPlaceholder)

                endofrow = _.min(possible_ends) // nächstmögliches Ende

                while(startofrow > 0 && ((foundlast_br = usePage.content.indexOf('<br', startofrow)) >= endofrow || usePage.content.indexOf('<br', startofrow) < 0)) {
                  startofrow--
                }
                if (
                    startofrow <= 0 ||
                    (
                        usePage.content.indexOf('<p', startofrow) < endofrow &&
                        usePage.content.indexOf('<p', startofrow) > -1
                    ) ||
                    (
                        usePage.content.indexOf('<div', startofrow) < endofrow &&
                        usePage.content.indexOf('<div', startofrow) > -1
                    )
                ) {
                  startofrow = startOfPlaceholder
                } else if (foundlast_br > 0) {
                  startofrow = foundlast_br
                }
                usePage.content = usePage.content.substr(0,startofrow) +''+ usePage.content.substr(endofrow)
              }

            } else {
              if (pconf.pc) {
                useValue = '<span style="color:'+ pconf.pc +';">'+ useValue +'</span>'
              }

              usePage.content = usePage.content.replace(pregex, useValue)
            }
          }
        })
      }

      usePage.link = getters.linkToPage( pageid )

      return usePage
    },

      sortedFeedbackQestions(state) {
          if (state.feedbackquestions === null || state.feedbackquestions.length < 1) {
              return []
          }

          const useLang = state.language === null || state.language !== 'de' ? 'en' : 'de'

          return _.map(
              _.orderBy(
                  _.filter(state.feedbackquestions, fbq => fbq.eventid === state.eventid && fbq.pid === parseInt(router.currentRoute.value.params.pid,10)), ['sortpos', 'objvalueid'], ['asc', 'asc']
              ),
              fbq => {
                  const ufbq = {...fbq}
                  ufbq.caption = ufbq.caption_de
                  delete ufbq.caption_de


                  if (fbq.hasOwnProperty('rating_options_de')) {
                      ufbq.rating_options = []
                      let optindex = 0

                      if (useLang === 'de') {
                          fbq.rating_options_de.forEach(rO => {
                              if (optindex > 0) {
                                  ufbq.rating_options.push({
                                      index: optindex,
                                      selected: (fbq.hasOwnProperty('answer') && optindex === parseInt(fbq.answer,10)),
                                      caption: rO
                                  })
                              }
                              optindex++
                          })
                      }
                      delete ufbq.rating_options_de
                  }


                  return ufbq
              }
          )
      },

    tText: (state) => (textid) => {
      if (!state.texts.hasOwnProperty(textid)) {
        return textid + ' NOT SET'
      }
      return state.texts[textid][state.language]
    },
    errors(state) {
      if (state.errors.length < 1) {
        return []
      }
      const useLang = state.language === null || state.language !== 'de' ? 'en' : 'de'
      return _.map(state.errors, (error) => {
        const useField = typeof error.field !== 'undefined' ? error.field : null
        return {
          text:error[useLang],
          field:useField
        }
      })
    },
    isTestuser(state) {
      return state.credentials.testuser
    }
  },
  actions: {
    updateLastRoute({ commit, state }, route) {
      if (route !== '/') {
        commit('updateLastRoute', route)
        updateStoreUserdata()
      }
    },
    loginUser({ commit, state }, credentials) {
      crud('login', { credentials })
          .then(lres => {
            if (lres.hasOwnProperty('success')) {
              commit('updateCredentials', lres.success)
              updateStoreUserdata()

              crud('getEventsForUser')
                  .then(evs => {
                    commit('setEventData', { data:evs })
                    state.events = [...evs]
                    commit('ResetEvent')
                    commit('gotoOverviewEvents')
                  })
                  .catch(err => {console.error(err)})
            } else {
              store.commit('clearErrors')
              store.commit('appendError', staticTexts.invalidLogin)
            }
          })
          .catch( err => { console.error(err) })
    },
    updateCredentials({ commit, state }, credentials) {
      commit('updateCredentials', credentials)
    },
    readCurrentUser({ commit, state, dispatch }) {
      db.user
          .toCollection()
          .last()
          .then(userdata => {
            commit('setUserData')
            if (typeof userdata === 'object' && userdata !== null) {
              const dbCredentials = {...userdata}
              commit('updateCredentials', dbCredentials)
              dispatch('readEventData')
            }
          })
    },


    readEventData({ commit, state, dispatch }) {
      db.event
        .toCollection()
        .toArray()
        .then(evs => {
          if (evs.length > 0) {
            state.events = [...evs]
            if (evs.length === 1) {
              commit('gotoEvent', _.head(evs).objvalueid)
            }else{
                commit('ResetEvent')
                commit('gotoOverviewEvents')
            }
          }

          dispatch('readEventDataFromServer')

        })
    },

    readEventDataFromServer({ commit, state }) {

      if (state.isOnline && !state.eventdataLoading) {
        state.eventdataLoading = true
        crud('getEventsForUser')
            .then(evs => {
                commit('setEventData', { data:evs })
                state.events = [...evs]
                if (evs.length === 1) {
                    commit('gotoEvent', _.head(evs).objvalueid)
                }else{
                    commit('ResetEvent')
                    commit('gotoOverviewEvents')
                }

                state.eventdataLoading = false

            })
            .catch(err => {
              state.eventdataLoading = false
              console.error(err)
            })
      }

    },



    readWeatherData({ commit, state }, weather) {
      if (state.isOnline) {
        crud('get_weather_data', {eventid: state.eventid})
            .then(wdata => {
              wdata.age = new Date()
              commit('setWeatherData', wdata)
            })
            .catch(err => { console.error(err) })
      } else if (typeof weather === 'object' && weather !== null) {
        db.weather
            .where('[lat+lon]')
            .equals([weather.lat, weather.lon])
            .last()
            .then(wdata => {
              commit('setWeatherData', {
                ...weather,
                data:wdata.data,
                noupdate:true
              })
            })
      }
    },
    readLocations({ commit, state }) {
      if (state.isOnline) {
        crud('getLocationsForEvent', {eventid: state.eventid})
            .then(ldata => {
              if (state.locations.length > 0) {
                const locationsNotInRes = _.filter(
                    state.locations,
                    ls => !ldata.some(olD => parseInt(olD.objvalueid,10) === parseInt(ls.objvalueid,10))
                )

                locationsNotInRes.forEach(lD => {
                  db.location
                      .where('objvalueid')
                      .equals(lD.objvalueid)
                      .delete()
                      .then(() => {})
                      .catch((err) => { console.error(err) })
                })
              }

              commit('setLocations', ldata)
            })
            .catch(err => { console.error(err) })
      } else {
        db.location
            .toCollection()
            .toArray()
            .then(ldata => {
              commit('setLocations', ldata)
            })
      }
    },

      getFeedbackQuestions({commit, state, dispatch}) {
          if (state.quotaExceeded && state.isOnline) {
              if (state.feedbackquestions === null) {
                  state.feedbackquestions = []
                  commit('setFeedbackQuestions', state.feedbackquestions)
              }

              dispatch('getFeedbackQuestionsFromServer')
          } else {
              db.feedbackquestion
                  .toCollection()
                  .toArray()
                  .then(fbq => {
                      if (!fbq) {
                          fbq = []
                      }
                      state.feedbackquestions = [...fbq]
                      commit('setFeedbackQuestions', state.feedbackquestions)
                      if (!state.isOnline) {
                          dispatch('getFeedbackAnswers')
                      }
                      dispatch('getFeedbackQuestionsFromServer')
                  })
                  .catch(err => {
                      console.error(err)
                      commit('setFeedbackQuestions', [])
                      dispatch('getFeedbackQuestionsFromServer')
                  })
          }

      },
      getFeedbackAnswers({commit, state}) {
          state.feedbackquestions.forEach(fbq => {
              db.feedbackanswer
                  .where('[objvalueid+eventid+pid]')
                  .equals([fbq.objvalueid, state.eventid, fbq.pid])
                  .last()
                  .then(fba => {
                      if (fba && !state.quotaExceeded) {
                          commit('setFeedbackAnswer', {fbq, fba})
                      } else {
                          const sfba = _.find(state.feedbackanswers, sfba => sfba.objvalueid === fbq.objvalueid && sfba.pid === fbq.pid && sfba.eventid === state.eventid)
                          if (sfba) {
                              commit('setFeedbackAnswer', {fbq, fba:sfba})
                          }
                      }
                  })
          })
      },
      getFeedbackQuestionsFromServer({commit, state, dispatch}) {
          if (state.isOnline && state.feedbackquestions !== null) {
              crud('getFeedbackQuestions', {eventid: state.eventid, pid: parseInt(router.currentRoute.value.params.pid,10)})
                  .then(fbq => {
                      if (state.feedbackquestions === null) {
                          return
                      } else {
                          fbq.forEach(q => {
                              const oldQuestion = _.find(state.feedbackquestions, oq => oq.objvalueid === q.objvalueid && oq.pid === q.pid)
                              if (!oldQuestion) {
                                  if (q.hasOwnProperty('deleted') && q.deleted) { return }

                                  if (!q.hasOwnProperty('allowed_to_send')) {
                                      q.allowed_to_send = false
                                  }
                                  if (!q.hasOwnProperty('sent')) {
                                      q.sent = false
                                  }
                                  state.feedbackquestions.push(q)
                              } else {
                                  if (q.hasOwnProperty('deleted') && q.deleted) {
                                      _.remove(state.feedbackquestions, oq => oq.objvalueid === q.objvalueid)
                                  } else {
                                      if (q.hasOwnProperty('caption_de')) {
                                          oldQuestion.caption_de = q.caption_de
                                      }
                                      if (q.hasOwnProperty('rating_options_de')) {
                                          oldQuestion.rating_options_de = q.rating_options_de
                                      }

                                      if (q.hasOwnProperty('question_type')) {
                                          oldQuestion.question_type = q.question_type
                                      }
                                      if (q.hasOwnProperty('sortpos')) {
                                          oldQuestion.sortpos = q.sortpos
                                      }
                                      if (q.hasOwnProperty('pid')) {
                                          oldQuestion.pid = q.pid
                                      }
                                  }
                              }
                          })

                          commit('setFeedbackQuestions', state.feedbackquestions)
                          dispatch('getFeedbackAnswers')
                      }
                  })
                  .catch(err => {
                      console.error(err)
                  })
          } else {
              console.error('Null')
          }
      },
      authorizeSendFeedbackAnswers({commit, state, dispatch}) {
          state.feedbackanswers = _.map(state.feedbackanswers, a => {
              if (a.sent === false && typeof a.answer !== 'undefined' && a.answer !== '0' && a.answer !== 0) {
                  a.allowed_to_send = true
              }
              return a
          })


          let data = JSON.parse(JSON.stringify(state.feedbackanswers))
          db.feedbackanswer.bulkPut(data)
              .catch(err => {
                  commit('logAppError', {
                      error: err,
                      time: new Date()
                  })
                  console.error('error bulkPut feedbackanswers')
                  console.error(err)
              })
              .finally(() => {
                  dispatch('sendFeedbackAnswers')
              })
      },
      sendFeedbackAnswers({commit, state, getters, dispatch}, forceOnline) {
          if (state.isOnline || forceOnline) {
              const getAnswers = () => {
                  if (state.quotaExceeded) {
                      return new Promise((resolve) => { resolve(state.feedbackanswers) })
                  } else {
                      return db.feedbackanswer
                          .toCollection()
                          .toArray()
                  }
              }

              getAnswers()
                  .then((fba) => {
                      if (fba && fba.length > 0) {
                          _.remove(fba, q => q.allowed_to_send === false || q.sent === true)

                          if (fba && fba.length > 0) {
                              if (state.eventid === null || state.eventid < 1) {
                                  window.setTimeout(() => {dispatch('sendFeedbackAnswers', forceOnline)}, 1000)
                                  return
                              }

                              crud('setFeedbackAnswers', {fba})
                                  .then((setRes) => {
                                      // Eventdaten refreshen wegen des Beantwortungszeitpunkts
                                      dispatch('getPages')

                                      if (setRes && setRes.length > 0) {
                                          const proccessed_questions = []

                                          setRes.forEach(q => {
                                              _.remove(state.feedbackanswers, sfba => sfba.id === q.id)
                                              proccessed_questions.push(q.id)
                                          })

                                          state.feedbackanswers = [...state.feedbackanswers]

                                          db.feedbackanswer
                                              .bulkDelete(proccessed_questions)
                                              .then(() => {
                                                  if (state.feedbackquestions !== null) {
                                                      dispatch('getFeedbackAnswers')
                                                  }
                                              })

                                          if (state.feedbackquestions !== null) {
                                              _.remove(state.feedbackquestions, oq => setRes.some(nq => nq.objvalueid === oq.objvalueid && nq.deleted && nq.deleted === true))
                                              state.feedbackquestions = _.map(state.feedbackquestions, q => {
                                                  if (setRes.some(nq => nq.objvalueid === q.objvalueid)) {
                                                      q.answer = ''
                                                      q.sent = false
                                                      q.allowed_to_send = false
                                                  }
                                                  return q
                                              })

                                              commit('setFeedbackQuestions', state.feedbackquestions)
                                          }


                                      }
                                  })
                                  .catch(err => {
                                      console.error(err)
                                  })
                          }
                      }
                  })
          }
      },
    getPageContent({commit, state}, pageid) {
      return new Promise((resolve, reject) => {
        if (state.isOnline) {
          state.pagesLoadTriggered.push(parseInt(pageid, 10))

          crud('getPageDataById', {eventid: state.eventid, pageid})
              .then(pagedata => {
                if (pagedata.hasOwnProperty('err')) {
                  commit('appendError', store.getters.tText('insufficientRights'))
                  console.error(pagedata.err)
                  return
                }

                if (pagedata.hasOwnProperty('content_en')) {
                  const fp = state.pages !== null ? _.find(state.pages, op => parseInt(op.objvalueid, 10) === parseInt(pageid, 10)) : null
                  if (fp) {
                    fp.content_de = pagedata.content_de
                    fp.content_en = pagedata.content_en
                    fp.content_update = pagedata.content_update
                    fp.force_save_offline = pagedata.force_save_offline
                    fp.meta = (typeof pagedata.meta !== 'string' ? JSON.stringify(pagedata.meta) : pagedata.meta)
                  } else {
                    console.error('Page ' + pageid + ' loaded but not found in page array.')
                  }
                } else {
                  console.error('Missing page infos for page ' + pageid)
                }

                _.remove(state.pagesLoadTriggered, pLT => pLT === parseInt(pageid, 10))

                resolve(pageid)

                commit('setPages', state.pages)
              })
              .catch(err => {
                _.remove(state.pagesLoadTriggered, pLT => pLT === parseInt(pageid, 10))
                reject()
                console.error(err)
              })
        } else {
          reject()
        }
      })
    },
    getPageKachelImage({commit, state}, pageid) {
      if (state.isOnline) {
        crud('getPageKachelfile', {pageid})
            .then(kachelres => {
              commit('setPageKachelImage', {
                pageid,
                kachel_image: kachelres.kachel_image,
                kachel_image_checksum: kachelres.kachel_image_checksum
              })
            })
            .catch(err => { console.error(err) })
      }
    },
    getPages({commit, state, dispatch}, eventid) {

      if (typeof eventid === 'number' && state.eventid !== eventid) {
        // Gotoevent ist zu langsam. Deshalb hier erneut setzen wenn abweichend.
        state.eventid = eventid
      }

      if (state.eventid > 0) {
        fetchData()

        db.page
            .toCollection()
            .toArray()
            .then(pages => {
              if (state.pages === null || state.pages.length < 1) {
                state.pages = pages ? pages : []
              }

              if (state.isOnline) {
                crud('getPagesForEvent', {eventid: state.eventid})
                    .then(opages => {

                      if (typeof opages === 'undefined' || (opages.length < 1 && (typeof state.pages === 'undefined' || state.pages === null || state.pages.length < 1))) {
                        // Keine Seiten Fehler
                        commit('appendError', store.getters.tText('insufficientRights'))
                        router.push('/')
                        return
                      }

                      // Seiten vergleichen

                      // Aus dem Store nehmen, wenn sie nicht mehr in diesem Ergebnis sind
                      const notInRes = _.filter(state.pages, fp => !opages.some(ap => parseInt(ap.objvalueid, 10) === parseInt(fp.objvalueid,10)))
                      notInRes.forEach(rmP => {
                        db.page
                            .where('objvalueid')
                            .equals(parseInt(rmP.objvalueid, 10))
                            .delete()
                            .then(() => {})
                            .catch((err) => {
                              commit('logAppError', {
                                error: err,
                                time: new Date()
                              })
                              console.error(err)
                            })

                        _.remove(state.pages, fp => parseInt(rmP.objvalueid, 10) === parseInt(fp.objvalueid,10))
                      })


                      // Sammeln von Seiten, die ein Update brauchen.
                      const pagestogetcontentfor = []

                      opages.forEach(ap => {
                        const fp = state.pages !== null ? _.find(state.pages, op => parseInt(op.objvalueid, 10) === parseInt(ap.objvalueid, 10)) : null
                        if (typeof fp !== "object" || fp === null) {
                          // Seite ist neu = Anhängen und Content holen

                          if (!ap.force_save_offline && state.isWKWebview) {
                            ap.content_de = null
                            ap.content_en = null
                          }

                          state.pages.push(ap)

                          const cpage = _.last(state.pages)
                          if (cpage.hasOwnProperty('content_update')) {
                            cpage.content_update = null
                          }

                          if (ap.force_save_offline || !state.isWKWebview) {
                            pagestogetcontentfor.push(ap)
                          }

                        } else {
                          // Seite ist alt, Rahmendaten aktualisieren.
                          fp.is_kachel = ap.is_kachel
                          fp.feedback_answered = ap.feedback_answered
                          fp.kachel_color = ap.kachel_color
                          fp.kachel_pos = ap.kachel_pos
                          fp.kachel_size = ap.kachel_size
                          //fp.meta = ap.meta
                          fp.pagename_de = ap.pagename_de
                          fp.pagename_en = ap.pagename_en
                          fp.parentid = ap.parentid
                          fp.rptype = ap.rptype
                          fp.showstop = ap.showstop
                          fp.sortpos = ap.sortpos
                          fp.timezone = ap.timezone
                          fp.searchable = ap.searchable
                          fp.force_save_offline = ap.force_save_offline
                          fp.kachel_image = ap.kachel_image
                          fp.kachel_image_checksum = ap.kachel_image_checksum


                          if ((!ap.hasOwnProperty('content_update') || ap.content_update === null) && ap.rptype < 1) {
                            // Neuer Eintrag ist eine Standardseite und hat noch keinen Content.
                            return
                          }

                          if ((!fp.hasOwnProperty('content_update') || (fp.content_update === null && (!state.isWKWebview || fp.force_save_offline))) || (ap.rptype === 3 || ap.rptype === 4) || (ap.rptype === 1 && !fp.meta)) {
                            // Alter Eintrag hatte keinen Content oder Eintrag ist keine Standardseite
                            pagestogetcontentfor.push(ap)
                            return
                          }

                          // Neuer und alter Eintrag hatten Content / Zeiten vergleichen
                          if (ap.content_update !== null && fp.content_update !== null) {
                            const apm = moment.tz(ap.content_update, 'Europe/Berlin')
                            const fpm = moment.tz(fp.content_update, 'Europe/Berlin')
                            if (apm.isAfter(fpm)) {
                              pagestogetcontentfor.push(ap)
                            }
                          }
                        }

                      })

                      commit('setPages', state.pages)

                      pagestogetcontentfor.forEach(gCf => { store.dispatch('getPageContent', gCf.objvalueid) })
                    })
                    .catch(err => { console.error(err) })
              }
            })
      } else {
        console.error('No Eventid')
      }
    },
    savePrivacySetting({commit, state}, termsAccepted) {
      crud('savePrivacySetting', { eventid: state.eventid,termsAccepted: (termsAccepted ? 'Yes':'No') })
          .then(res => {
            commit('savedPrivacySettings', { eventid: state.eventid,termsAccepted })
          })
          .catch(err => { console.error(err) })
    },
    saveTermsSetting({commit, state}, termsAccepted) {
      crud('saveTermsSetting', { eventid: state.eventid,termsAccepted: (termsAccepted ? 'Yes':'No') })
          .then(res => {
            commit('savedTermsSettings', { eventid: state.eventid,termsAccepted })
          })
          .catch(err => { console.error(err) })
    },
    saveSmsSetting({commit, state}, termsAccepted) {
      crud('saveSmsSetting', { eventid: state.eventid,termsAccepted: (termsAccepted ? 'Yes':'No') })
          .then(res => {
            commit('savedSmsSettings', { eventid: state.eventid,termsAccepted })
          })
          .catch(err => { console.error(err) })
    },
    cleanCache({commit, state}) {
      // Alle Daten löschen.
      state.searchedCredentials = false
      router.push('/')
      db.delete()
          .then(() => {
            state.searchedCredentials = true
            db.open()
                .catch(err => {
                  commit('logAppError', {
                    error: err,
                    time: new Date()
                  })
                  db.open()
                  console.error(err)
                })
          })
          .catch((err) => {
            commit('logAppError', {
              error: err,
              time: new Date()
            })
            console.error(err)
            state.searchedCredentials = true
          })
      state.events = []
      state.eventid = null
      state.credentials = {
        objvalueid: null,
        groupid: null,
        firstname: null,
        lastname: null,
        salutation: null,
        title: null,
        language: null,
        lastroute: null,
        logintime: null,
        testuser: false,
        barcode: null,
        jwt: null
      }
      state.timezone = ''
      state.pages = []
      state.locations = []
      state.readPages = false
      state.weatherData = null
    }
  }
})

setCrudStore(store)



store.commit('testIfMobileDevice')

const fetchData = () => {
  store.dispatch('readLocations')
  store.dispatch('readWeatherData', store.getters.eventData.weather)
}

const updateStoreUserdata = () => {
  if (store.state.credentials.objvalueid === null || store.state.credentials.jwt === null || !db.isOpen()) {
    return false
  }

    const rawcredentials = toRaw(store.state.credentials);
  db.user.put(rawcredentials)
      .then(key => {})
      .catch(err => {
        store.commit('logAppError', {
          error: err,
          time: new Date()
        })
        console.error(err)
      })

  return true
}

if (navigator.onLine) {
    store.commit('goOnline')
    store.dispatch('sendFeedbackAnswers', true)
} else {
  store.commit('goOffline')
}

window.addEventListener('online', () => {
    store.commit('goOnline')
    store.dispatch('sendFeedbackAnswers', true)
})
window.addEventListener('offline', () => { store.commit('goOffline') })
document.addEventListener('visibilitychange', () => {
  store.commit('visibilityChanged')
  if (document.visibilityState === 'visible') {
    store.dispatch('getPages')
  }
})

window.addEventListener ('unhandledrejection', (event) => {
  let reason = event.reason
  store.commit('logAppError', {
    reason: (reason && (reason.stack || reason)),
    time: new Date()
  })

  if (!db.isOpen()) {
    db.open()
  }
})

const initApp = () => {
  store.commit('visibilityChanged')
  store.commit('setLanguage')
  store.dispatch('readCurrentUser')
}

if (!db.isOpen()) {
  db.open()
      .then(() => {
        if (db.isOpen()) {
          initApp()
        }
      })
      .catch((err) => {
        store.commit('logAppError', {
          error: err,
          time: new Date()
        })
        store.commit('appendError', staticTexts.offlineStoreOffline)
        console.error(err)
      })
} else {
  initApp()
}

export default store;
