<template>
    <div class="navigation-topbar-mobile__crest ">
        <router-link v-if="!hideNavigation" class="navigation-topbar-mobile__logo" :to="{ path:homelink }">
          <span class="splash-logo"></span>
        </router-link>
    </div>
</template>

<script>
export default({
    name: "MobileLogo",
    props: ['hideNavigation', 'homelink']
});
</script>

<style scoped lang="scss">
    .navigation-topbar-mobile__crest {
        width:50%;
        max-height:4rem;

      .splash-logo {
        position: relative;
        display: block;
        margin-top: .5rem;
        height:3.5rem;
        background: transparent url("~@/assets/svg/hagebau_Logo_RGB.svg") no-repeat;
        background-position: center;
        background-size: contain;
      }


    }


</style>