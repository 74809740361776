<template>
    <div :class="'mappin'+ (pin.isCentered ? ' pin-centered':'') + (mypospin ? ' graypin':'')" :style="pinPosition" @click="togglePin">
        <transition name="pindrop">
            <svg v-show="pinshow && pin.isVisible" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" version="1.1" viewBox="0 -128 2048 2048" class="pag-map-marker">
                
                <g transform="matrix(1 0 0 -1 0 1920)">
                    <linearGradient v-if="pin.isVisible" id="pin-gradient" x1="0%" y1="100%" x2="100%" y2="0%">
                        <stop offset="0%" stop-color="var(--highlight-color-lighter)" />
                        <stop offset="5%" stop-color="var(--highlight-color)" />
                        <stop offset="50%" stop-color="var(--highlight-color)" />
                        <stop offset="100%" stop-color="var(--highlight-color-darker)" />
                    </linearGradient>
                    <path class="pinbehind" fill="currentColor"
                          d="M 347.11862,1333.8305 Q 347.11862,1106.4407 616.81353,635.79661 738.44065,418.98305 865.35591,239.18645 L 1024,6.5084787 1182.6441,239.18645 Q 1304.2711,418.98305 1431.1865,635.79661 1700.8814,1106.4407 1700.8814,1333.8305 1700.8814,1624.678 1502.5763,1830.9153 1304.2711,2037.1525 1024,2037.1525 743.72879,2037.1525 545.42371,1830.9153 347.11862,1624.678 347.11862,1333.8305 Z" />
                    <path fill="currentColor"
                          d="M 347.11862,1333.8305 Q 347.11862,1106.4407 616.81353,635.79661 738.44065,418.98305 865.35591,239.18645 L 1024,6.5084787 1182.6441,239.18645 Q 1304.2711,418.98305 1431.1865,635.79661 1700.8814,1106.4407 1700.8814,1333.8305 1700.8814,1624.678 1502.5763,1830.9153 1304.2711,2037.1525 1024,2037.1525 743.72879,2037.1525 545.42371,1830.9153 347.11862,1624.678 347.11862,1333.8305 Z" />
                </g>
            </svg>
        </transition>

        <transition name="pindrop">
            <svg v-show="pinshow && pin.isVisible" v-if="pin.props.locationtype === 3" aria-hidden="true" focusable="false" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512" class="fa-plane mapicon"><path fill="currentColor" d="M480 192H365.71L260.61 8.06A16.014 16.014 0 0 0 246.71 0h-65.5c-10.63 0-18.3 10.17-15.38 20.39L214.86 192H112l-43.2-57.6c-3.02-4.03-7.77-6.4-12.8-6.4H16.01C5.6 128-2.04 137.78.49 147.88L32 256 .49 364.12C-2.04 374.22 5.6 384 16.01 384H56c5.04 0 9.78-2.37 12.8-6.4L112 320h102.86l-49.03 171.6c-2.92 10.22 4.75 20.4 15.38 20.4h65.5c5.74 0 11.04-3.08 13.89-8.06L365.71 320H480c35.35 0 96-28.65 96-64s-60.65-64-96-64z" class=""></path></svg>
        </transition>
        
        <transition name="pindrop">
            <svg v-show="pinshow && pin.isVisible" v-if="pin.props.locationtype === 2" aria-hidden="true" focusable="false" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 640 512" class="fa-concierge-bell mapicon"><path fill="currentColor" d="M32 32c17.7 0 32 14.3 32 32V320H288V160c0-17.7 14.3-32 32-32H544c53 0 96 43 96 96V448c0 17.7-14.3 32-32 32s-32-14.3-32-32V416H352 320 64v32c0 17.7-14.3 32-32 32s-32-14.3-32-32V64C0 46.3 14.3 32 32 32zm144 96a80 80 0 1 1 0 160 80 80 0 1 1 0-160z" class=""></path></svg>
        </transition>
        
        <transition name="pindrop">
            <svg v-show="pinshow && pin.isVisible" v-if="pin.props.locationtype === 4" aria-hidden="true" focusable="false" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 416 512" class="fa-utensils mapicon"><path fill="currentColor" d="M207.9 15.2c.8 4.7 16.1 94.5 16.1 128.8 0 52.3-27.8 89.6-68.9 104.6L168 486.7c.7 13.7-10.2 25.3-24 25.3H80c-13.7 0-24.7-11.5-24-25.3l12.9-238.1C27.7 233.6 0 196.2 0 144 0 109.6 15.3 19.9 16.1 15.2 19.3-5.1 61.4-5.4 64 16.3v141.2c1.3 3.4 15.1 3.2 16 0 1.4-25.3 7.9-139.2 8-141.8 3.3-20.8 44.7-20.8 47.9 0 .2 2.7 6.6 116.5 8 141.8.9 3.2 14.8 3.4 16 0V16.3c2.6-21.6 44.8-21.4 48-1.1zm119.2 285.7l-15 185.1c-1.2 14 9.9 26 23.9 26h56c13.3 0 24-10.7 24-24V24c0-13.2-10.7-24-24-24-82.5 0-221.4 178.5-64.9 300.9z" class=""></path></svg>
        </transition>
        
        <transition name="pindrop">
            <svg v-show="pinshow && pin.isVisible" v-if="mypospin" aria-hidden="true" focusable="false" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512" class="fa-walking mapicon"><path fill="currentColor" d="M208 96c26.5 0 48-21.5 48-48S234.5 0 208 0s-48 21.5-48 48 21.5 48 48 48zm94.5 149.1l-23.3-11.8-9.7-29.4c-14.7-44.6-55.7-75.8-102.2-75.9-36-.1-55.9 10.1-93.3 25.2-21.6 8.7-39.3 25.2-49.7 46.2L17.6 213c-7.8 15.8-1.5 35 14.2 42.9 15.6 7.9 34.6 1.5 42.5-14.3L81 228c3.5-7 9.3-12.5 16.5-15.4l26.8-10.8-15.2 60.7c-5.2 20.8.4 42.9 14.9 58.8l59.9 65.4c7.2 7.9 12.3 17.4 14.9 27.7l18.3 73.3c4.3 17.1 21.7 27.6 38.8 23.3 17.1-4.3 27.6-21.7 23.3-38.8l-22.2-89c-2.6-10.3-7.7-19.9-14.9-27.7l-45.5-49.7 17.2-68.7 5.5 16.5c5.3 16.1 16.7 29.4 31.7 37l23.3 11.8c15.6 7.9 34.6 1.5 42.5-14.3 7.7-15.7 1.4-35.1-14.3-43zM73.6 385.8c-3.2 8.1-8 15.4-14.2 21.5l-50 50.1c-12.5 12.5-12.5 32.8 0 45.3s32.7 12.5 45.2 0l59.4-59.4c6.1-6.1 10.9-13.4 14.2-21.5l13.5-33.8c-55.3-60.3-38.7-41.8-47.4-53.7l-20.7 51.5z" class=""></path></svg>
        </transition>
        
        <transition name="fade">
            <div v-if="pinnameToggled && pin.isVisible" class="maptooltip">{{ $store.state.language !== 'de' ? pin.props.markertext_en : pin.props.markertext_de }}</div>
        </transition>
    </div>
</template>

<script>
export default({
    name: "MapPin",
    data() {
        return {
            pinnameToggled: false,
            pinshow: false,
            timeout: null
        }
    },
    mounted() {
        this.$watch('pin.isVisible', (newVis, oldVis) => {
            if (!newVis) {
                if (this.timeout) {
                    window.clearTimeout(this.timeout)
                    this.timeout = null
                }
            } else {
                this.pinnameToggled = false
                this.toggleWithTimeout()
            }
        })
        
        if (typeof this.fadeInOffset !== 'number') {
            this.pinshow = true
        } else {
            this.toggleWithTimeout()
        }
    },
    props: ['pin', 'fadeInOffset'],
    computed: {
        pinPosition() {
            if (!this.pin || this.pin === null || !this.pin.hasOwnProperty('pixelpos') || this.pin.pixelpos === null || this.pin.pixelpos.length !== 2) {
                return ''
            }
            return 'left:'+ (this.pin.pixelpos[0] - 22.5) + 'px;top:'+ (this.pin.pixelpos[1] - 45) + 'px;'
        },
        mypospin() {
            return this.pin.props.locationtype === -1
        }
    },
    methods: {
        togglePin() {
            if (this.pin.props.pageid !== null) {
                const pagelink = this.$store.getters.linkToPage(this.pin.props.pageid)
                if (typeof pagelink === 'string') {
                    this.$router.push(pagelink)
                    return
                }
            }
            
            this.pinnameToggled = !this.pinnameToggled
            if (this.pinnameToggled) {
                this.$emit('pintoggled', this.pin)
            }
        },
        toggleWithTimeout() {
            const vm = this
            
            if (vm.timeout) {
                window.clearTimeout(vm.timeout)
                vm.timeout = null
            }
            
            vm.timeout = window.setTimeout(() => {
                vm.pinshow = true
                vm.pinnameToggled = true

                vm.timeout = window.setTimeout(() => {
                    vm.pinnameToggled = false
                    vm.timeout = null
                }, 1100)

            }, vm.fadeInOffset)
        }
    },
    beforeUnmount() {
        if (this.timeout) {
            window.clearTimeout(this.timeout)
            this.timeout = null
        }
    }
});
</script>

<style scoped lang="scss">
    @use "sass:math";
    $marker-size:45px;

    @keyframes pulsecentered {
        from {
            stroke-opacity: 0;
            stroke-width: 0;
            stroke: #fff;
        }
        to {
            stroke-opacity: .5;
            stroke-width: 400;
            stroke: var(--highlight-color);
        }
    }
    
    .mappin {
        z-index: 110;
        position: absolute;
        top:100px;
        left:100px;
        width:$marker-size;
        height:$marker-size;
        /*
        display: flex;
        justify-content:center;
        align-items:center;
        background-color:var(--highlight-color);
        */
        color:#fff;
        font-weight:700;
        
        .pag-map-marker {
            position: absolute;
            width:$marker-size;
            height:$marker-size;
            top:0px;
            left:0px;
            overflow: visible;
            
            path {
                fill:url(#pin-gradient) var(--highlight-color);
                fill-opacity:1;
                
                & {
                    stroke-linejoin: round;
                    stroke-opacity: 0;
                    stroke: transparent;
                    paint-order:stroke markers fill;
                }
            }
        }
        
        &.pin-centered {
            .pag-map-marker {
                .pinbehind {
                    animation: pulsecentered 1s alternate infinite;
                }
            }
        }
        
        .mapicon {
            position: absolute;
            width: math.div($marker-size, 2) ;
            height: math.div($marker-size, 2) ;
            top:3px;
            left: math.div($marker-size - math.div($marker-size, 2), 2);

            path {
                fill:#fff;
                fill-opacity:1;
            }
        }
        
        .maptooltip {
            background-color:var(--highlight-color);
            padding:2px;
            position: absolute;
            left:($marker-size * (-1));
            top:$marker-size;
            width:($marker-size * 3);
            text-align: center;
            font-size:1rem;
            line-height: 1.2rem;
            z-index: 110;
        }

        .fade-enter-active, .fade-leave-active {
            transition: opacity .5s, top .5s;
        }
        .fade-enter, .fade-leave-to {
            opacity: 0;
            top:16px;
        }
        
        &.graypin {
            .pag-map-marker {
                path {
                    fill: #626669;
                }
            }

            .maptooltip {
                background-color:#626669;
            }
        }
    }

    .pindrop-enter-active, .pindrop-leave-active {
        transition: opacity .5s, margin-top 1s;
    }
    .pindrop-enter, .pindrop-leave-to {
        opacity: 0;
        margin-top:-64px;
    }
</style>