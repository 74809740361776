<template>
    <svg viewBox="0 0 50 50" preserveAspectRatio="xMidYMid meet" class="hagebau-spinner">
        <circle r="15" cx="25" cy="25" fill="transparent" stroke-dasharray="95" stroke-dashoffset="0"></circle>
        <circle transform="rotate(-90), translate(-50,0)" class="bar" r="15" cx="25" cy="25" fill="transparent" stroke-dasharray="95" stroke-dashoffset="0"></circle>
    </svg>
</template>

<script>
export default({
    name: "Spinner"
});
</script>

<style scoped lang="scss">
    @keyframes spin-spinner {
        0% {stroke-dashoffset: 95}
        100% {stroke-dashoffset: 0}
    }
    
    svg {
        z-index: 1000;
        width:100px;
        height:100px;
    }

    circle {
        stroke-dashoffset: 0;
        transition: stroke-dashoffset 1s linear;
        stroke: #eff0f1;
        stroke-width: 1px;
    }

    .bar {
        stroke: var(--highlight-color);
        animation: spin-spinner 1.5s linear infinite both;
    }
</style>