<template>
    <section>
        <div class="row">
            <div class="col">
                <h2 class="-pui-text-size-2-thin pui-text--align-center pui-text--wrap pui-pb--24">
                    {{ showDayTime === 'day' ? $store.getters.tText('duringday') : $store.getters.tText('duringnight') }}
                </h2>
            </div>
        </div>
        <div class="row">
            <div class="col">
                <table class="table" aria-describedby="">
                    <tbody>
                    <template v-for="wD in weatherByDay" :key="wD.day.format('YYYY-MM-DD')">
                    <tr v-if="wD[ showDayTime === 'day' ? 'daytime' : 'nighttime' ].icon">
                        <td class="weekday">{{ wD.day.format('dd') }}</td>
                        <td class="date-col" v-if="$store.state.language === 'en'">{{ wD.day.format('MMM') }}</td>
                        <td class="date-col" v-if="$store.state.language === 'en'">{{ wD.day.format('Do') }}</td>
                        <td class="date-col" v-if="$store.state.language === 'de'">{{ wD.day.format('D')+ '.' }}</td>
                        <td class="date-col" v-if="$store.state.language === 'de'">{{ wD.day.format('M')+ '.' }}</td>
                        <td class="icon-container"><WeatherIcon :icon="wD[ showDayTime === 'day' ? 'daytime' : 'nighttime' ].icon" size="32" type="overview" /></td>
                        <td class="deg-col">{{ Math.round( wD[ showDayTime === 'day' ? 'daytime' : 'nighttime' ].maxtemp ) }} °C</td>
                        <td class="deg-col">{{ Math.round( wD[ showDayTime === 'day' ? 'daytime' : 'nighttime' ].mintemp ) }} °C</td>
                        <td>
                            <router-link :to="{ name:'weathersingleday', params: { eventid: $route.params.eventid, weatherdate:(wD.day.format('YYYY-MM-DD')) } }">
                                <span class="navigation-topbar-mobile__flyout-icon navigation-topbar-mobile__flyout-icon--right icon icon--arrow-right-thin"></span>
                            </router-link>
                        </td>
                    </tr>
                    </template>
                    </tbody>
                </table>
            </div>
        </div>
        <div class="row">
            <div class="col">
                <button v-if="toggleButton" type="button" ref="toggleButton" class="btn btn-primary btn btn-block" @click="toggleDay">
                    {{ showDayTime !== 'day' ? $store.getters.tText('duringday') : $store.getters.tText('duringnight') }}
                </button>
            </div>
        </div>
    </section>
</template>

<script>
import WeatherIcon from '@/components/WeatherIcon.vue'
import moment from 'moment-timezone'

export default({
    name: "WeatherOverview",
    data() {
        return {
            showDayTime: 'day',
            toggleButton: true
        }
    },
    components: {
        WeatherIcon
    },
    methods: {
        toggleDay(e) {
            this.showDayTime = this.showDayTime !== 'day' ? 'day':'night'
            this.toggleButton = false
            this.$nextTick(() => {
                this.toggleButton = true
            })
        }
    },
    computed: {
        weatherByDay() {
            const weatherByDay = []
            const now = moment().tz(this.$store.getters.eventData.timezone)

            if (this.$store.state.weatherData !== null && this.$store.state.weatherData.hasOwnProperty('list') && this.$store.state.weatherData.list.length > 0) {
                this.$store.state.weatherData.list.forEach(wD => {
                    const dateMoment = moment.utc(wD.dt_txt.replace(/ /, 'T')).tz(this.$store.getters.eventData.timezone)
                    const hour = parseInt(dateMoment.format('k'),10)

                    if (dateMoment.isAfter(now)) {
                        let useDay = false
                        if (
                            !weatherByDay.some(sD => {
                                const sameDay = dateMoment.isSame(sD.day, 'day')
                                useDay = sD
                                return sameDay
                            })
                        ) {
                            weatherByDay.push({
                                day: dateMoment,
                                daytime: {
                                    icon: null,
                                    maxtemp: null,
                                    mintemp: null
                                },
                                nighttime: {
                                    icon: null,
                                    maxtemp: null,
                                    mintemp: null
                                },
                                entries: []
                            })

                            useDay = weatherByDay[((weatherByDay.length - 1))]
                        }
                        
                        if (hour > 6 && hour < 15) {
                            useDay.daytime.icon = _.head(wD.weather).icon
                        } else if (hour > 20) {
                            useDay.nighttime.icon = _.head(wD.weather).icon
                        }
                        
                        if (hour > 6 && hour < 21) {
                            if (useDay.daytime.maxtemp === null || useDay.daytime.maxtemp < wD.main.temp_max) {
                                useDay.daytime.maxtemp = wD.main.temp_max
                            }
                            if (useDay.daytime.mintemp === null || useDay.daytime.mintemp > wD.main.temp_min) {
                                useDay.daytime.mintemp = wD.main.temp_min
                            }
                        } else {
                            if (useDay.nighttime.maxtemp === null || useDay.nighttime.maxtemp < wD.main.temp_max) {
                                useDay.nighttime.maxtemp = wD.main.temp_max
                            }
                            if (useDay.nighttime.mintemp === null || useDay.nighttime.mintemp > wD.main.temp_min) {
                                useDay.nighttime.mintemp = wD.main.temp_min
                            }
                        }

                        useDay.entries.push(wD)
                    }
                })
            }

            return weatherByDay
        }
    }
});
</script>

<style scoped lang="scss">
    .table {
        width:100%;
        
        td {
            text-align:right;
            
            &.date-col {
                padding-left:0;
            }
            
            &.weekday {
                text-align: left;
            }
            
            &.icon-container {
                text-align: center;
            }
            
            &.deg-col {
                white-space: nowrap;
            }
        }
        
        a {
            color:var(--highlight-color);
        }

        @media (max-width: 320px) {
            td {
                padding:10px 5px;
            }
            
            td:first-child {
                padding-left:0;
            }
            td:last-child {
                padding-right:0;
            }
        }
    }
</style>