<template>
    <div>
        <div class="b-title-wrapper" data-lazy="true">
            <div class="b-title-headline-text">
                <h1>
                    <span>{{ pageData.pagename }}</span>
                </h1>
            </div>
        </div>
        <div v-if="pageData.searchable" :class="'searchfield'+ (scrollOffset > 50 ? ' searchbox-fixed':' searchbox-relative')+ ' pui-grid'">
            <div :class="'pui-grid__child pui-grid__child--size-' + (matches < 1 ? '12':'10')">
                <input v-model="findString" :placeholder="$store.getters.tText('search')" ref="findfield" @focus="focusAndEmpty" @keydown.enter.prevent="null">
                <svg @click="$refs.findfield.focus()" aria-hidden="true" focusable="false" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" class="searchicon"><path fill="currentColor" d="M508.5 468.9L387.1 347.5c-2.3-2.3-5.3-3.5-8.5-3.5h-13.2c31.5-36.5 50.6-84 50.6-136C416 93.1 322.9 0 208 0S0 93.1 0 208s93.1 208 208 208c52 0 99.5-19.1 136-50.6v13.2c0 3.2 1.3 6.2 3.5 8.5l121.4 121.4c4.7 4.7 12.3 4.7 17 0l22.6-22.6c4.7-4.7 4.7-12.3 0-17zM208 368c-88.4 0-160-71.6-160-160S119.6 48 208 48s160 71.6 160 160-71.6 160-160 160z" class=""></path></svg>
            </div>
            <div v-if="matches > 0" class="pui-grid__child pui-grid__child--size-2 matchindicator">
                {{ matches }}
            </div>
        </div>
        <section v-html="contentWithSearchHighlights" ref="pagecontentwrapper"></section>
        <section v-if="videosForPage.length > 0" class="videoswrapper">
            <div class="row" v-for="viP in videosForPage" :key="'videoforpage'+ viP.index">
                <div class="col">
                    <VideoPlayer
                        :src="'/hagebau/events/videos/pagetemplate/'+ pageData.objvalueid +'/'+ viP.name +'/index.m3u8'"
                        :poster="viP.prev"
                        :playerid="'video-'+ viP.index"
                    />
                </div>
            </div>
        </section>
        <SubpageButtons />
    </div>
</template>

<script>
import SubpageButtons from '@/components/SubpageButtons.vue'
import VideoPlayer from '@/components/VideoPlayer.vue'
import _ from 'lodash'

const escapeRegExp = (s) => {
    return s.replace(/[-/\\^$*+?.()|[\]{}]/g, '\\$&')
}

const scrollToFirstMatch = _.debounce(() => {
    if (parseInt(window.scrollY,10) > 20) {
        return
    }
    window.scrollTo(0, 0)
    const matches = document.querySelector('.b-standard-module .ismatch')
    const elPos = parseInt(matches.getBoundingClientRect().top ,10) - 60
    window.scrollTo(0, elPos)
}, 500)

export default({
    name: "HTMLContent",
    data() {
        return {
            findString: '',
            matches: 0,
            scrollOffset:0
        }
    },
    methods: {
        findInContent() {
            this.matches = 0

            if (!this.$refs.pagecontentwrapper) {
                return
            }

            const currentText = this.$refs.pagecontentwrapper.textContent
            const findRegex = new RegExp(escapeRegExp(this.findString), 'ig')

            if (this.findString.length > 2 && findRegex.test(currentText)) {
                let searchResults = null
                do {
                    searchResults = findRegex.exec(currentText)
                    this.matches++
                } while (searchResults)
                
            }
        },
        setScrollOffset() {
            this.scrollOffset = window.scrollY
        },
        focusAndEmpty() {
            this.findString = ''
        },
        setGroupColor() {
            if (this.$store.getters.groupColor) {
                this.$el.style.setProperty('--group-color', this.$store.getters.groupColor)
                this.$el.style.setProperty('--group-color-text', this.$store.getters.groupColor)
            }
        }
    },
    components: { SubpageButtons, VideoPlayer },
    created() {
        this.$watch('pageData.content', this.findInContent)
        this.$watch('$store.getters.groupColor', this.setGroupColor)
    },
    mounted() {
        this.setScrollOffset()
        this.setGroupColor()
        window.addEventListener('scroll', this.setScrollOffset)
    },
    beforeUnmount() {
        window.removeEventListener('scroll', this.setScrollOffset)
    },
    computed: {
        pageData() {
            return this.$store.getters.selectedPage( parseInt(this.$route.params.pid,10) )
        },
        videosForPage() {
            if (
                this.pageData !== null &&
                "meta" in this.pageData &&
                this.pageData.meta !== null &&
                typeof this.pageData.meta === 'object' &&
                this.pageData.meta.hasOwnProperty('videos') &&
                this.pageData.meta.videos
            ) {
                let videoindex = 0;
                return _.map(
                    this.pageData.meta.videos,
                    v => {
                        v.index = videoindex++
                        return v
                    }
                )
            }
            return []
        },
        contentWithSearchHighlights() {
            let pageDataContentHTML = this.$store.getters.selectedPage( parseInt(this.$route.params.pid,10) ).content

            if (typeof pageDataContentHTML !== "string") {
                return ''
            }

            pageDataContentHTML = pageDataContentHTML.replace(/col-xs-(\d+)/g, 'col-$1')

            if (this.findString === '' || this.findString.length < 3 || this.matches < 1) {
                return pageDataContentHTML
            } else {
                const findRegex = new RegExp('('+ escapeRegExp(this.findString)+ ')', 'ig')
                const reversefindRegex = new RegExp('<([^>]*)<span class="ismatch">('+ escapeRegExp(this.findString)+ ')</span>', 'ig')
                this.$nextTick(() => {
                    scrollToFirstMatch()
                })
                return pageDataContentHTML.replace(findRegex, '<span class="ismatch">$1</span>').replace(reversefindRegex, '<$1$2').replace(reversefindRegex, '<$1$2').replace(reversefindRegex, '<$1$2')
            }
        }
    },
    watch: {
        findString() {
            this.findInContent()
        }
    }
});
</script>

<style lang="scss">
    @use "sass:math";
    @import "../../style/just_variables";

    .videoswrapper {
        margin:2rem math.div($grid-gutter-width , -2);
    }

    img {
        display: inline-block;
        width:100%;
        height:auto;

        -webkit-touch-callout: default;
        -webkit-user-select: all;
    }
    
    .ismatch {
        background-color:yellow !important;
    }

    .pui-left-divider--groupcolor {
        border-color:var(--group-color);
    }

    .group-color--text {
        color:var(--group-color-text);
    }

    .car-spec-icon {
        color:var(--highlight-color);
    }
    
    .searchfield {
        position: relative;
        background-color: #fff;
        padding-top:6px;
        padding-bottom:6px;
        margin-left: 0;
        margin-right: 0;
        height:40px;
        
        
        border-bottom: 1px solid #c8cacb;
        
        &.searchbox-fixed {
            position: fixed;
            top:0;
            left:0;
            font-size: inherit;
        }
        
        .searchicon {
            width:1em;
            height:1em;
            position: absolute;
            left:7.14286%;
            top:10px;
            
        }
        
        .matchindicator {
            text-align: center;
        }
        
        width:100%;

        padding-left: 7.14286%;
        padding-right: 7.14286%;
        
        input {
            width: 100%;
            border:0;
            outline: none;
            padding-left:16px;
            font-size:inherit;
            background: transparent;
        }
    }
</style>