<template>
    <div>
        <div class="b-title-wrapper" data-lazy="true">
            <div class="b-title-headline-text">
                <h1>
                    <span>{{ $store.getters.tText('weather') }} in {{ cityName }}</span>
                </h1>
            </div>
        </div>

        <router-view />
    </div>
</template>

<script>
export default({
    name: "Weather",
    computed: {
        cityName() {
            return this.$store.getters.eventData.weather.cityName
        }
    }
});
</script>

<style scoped lang="scss">

</style>