<template>
  <div id="app" class="container-md">
    <router-view/>
  </div>
</template>

<script>
export default({
  name: 'App',
  components: {}
});
</script>

<style lang="scss">
//$font-path: resolve('@project/css/webfonts/');
$font-path: '~@/assets/fonts/';
$asset-path: '~@/assets/';



body {
  -webkit-user-select: none;
  -webkit-tap-highlight-color: transparent;
  -webkit-touch-callout: none;

  --navigation-background:#fff;
  --navigation-color:inherit;
  --kachel-background:#313639;
  --kachel-color:#fff;
  --event-background:#313639;
  --event-color:#fff;
  --highlight-color:#DD0026;
  --highlight-color-darker:darken(#DD0026,80%);
  --highlight-color-lighter:lighten(#DD0026,60%);
  --arrow-color:var(--kachel-color);
  --group-color:#c8cacb;
  --group-color-text:#000;
}

  @import "../style/bootstrap";
  @import "../style/app_overwrite";

  a:not([href]):not([tabindex]), .m-101__button__text {
    color:var(--navigation-color);
  }
  
  .pid__topbar-navigation .navigation-topbar-mobile, .m-00-header {
    background-color: var(--navigation-background);
  }
  
  .pid__topbar-navigation .navigation-topbar-mobile__icon--active, .pid__topbar-navigation .navigation-topbar-mobile__icon:hover, .pid__topbar-navigation .navigation-topbar-mobile__link-title--active, .pid__topbar-navigation .navigation-topbar-mobile__flyout-icon--right {
    color:var(--highlight-color);
  }

  p, pre {
    margin: 0 0;
  
    &:first-child {
      margin-top:0;
    }
  
    &:last-child {
      margin-bottom:0;
    }
  }
  
</style>
