<template>
    <div>
        <div class="b-title-wrapper" data-lazy="true">
            <div class="b-title-headline-text">
                <h1>
                    <span>{{ $store.getters.tText('pleaseConfirm') }}</span>
                </h1>
            </div>
        </div>
        <section class="row">
            <div class="col">
                <br>
                <router-link class="btn btn-primary btn btn-block" :to="{ path:'/logout/confirm' }">
                    {{ $store.getters.tText('logout') }}
                </router-link>
                <a class="btn btn-primary btn btn-block" href="#" @click.prevent="$router.go(-1)">
                    {{ $store.getters.tText('back') }}
                </a>
            </div>
        </section>
    </div>
</template>

<script>
export default({
    name: "LogoutConfirm",
    computed: {
        useLang() {
            return this.$store.state.language === null || this.$store.state.language !== 'de' ? 'en' : 'de'
        }
    }
});
</script>

<style scoped lang="scss">
    .padding-same--24 + .padding-same--24 {
        margin-top: 24px;
    }

    .b-standard-module {
        padding-bottom: 5px;
    }
</style>