<template>
    <div>
        <div class="b-title-wrapper" data-lazy="true">
            <div class="b-title-headline-text">
                <h1>
                    <span>{{ $store.getters.tText('map') }} {{ mapName }}</span>
                </h1>
            </div>
        </div>

        <div class="row">
            <SingleMap />
        </div>
        <div class="row">
            <div class="col">
                <p>
                    <small>Kartendaten &copy; Open Street Map Contributors</small><br>&nbsp;
                </p>
            </div>
        </div>
    </div>
</template>

<script>
import SingleMap from '@/components/Map.vue'

export default({
    name: "MapView",
    components: { SingleMap },
    mounted() {
        this.$emit('pagemounted')
    },
    computed: {
        mapName() {
            return this.$store.getters.eventData.map.cityName
        }
    }
});
</script>

<style scoped>

</style>