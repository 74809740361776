<template>
    <div>
        <div class="b-title-wrapper" data-lazy="true">
            <div class="b-title-headline-text">
                <h1>
                    <span>{{ pageData.pagename }}</span>
                </h1>
            </div>
        </div>
        <div class="row">
          <div class="col-12 col-sm-12">
            <section v-if="!sendTriggered && !pageData.feedback_answered" class="b-standard-module mt-5 mb-5">
                <div v-if="$route.name === 'feedback'" v-html="pageData.content"></div>
                <div v-if="fbQuestions.length > 0 && $route.name === 'feedbackquestion'" class="feedback-answerwrapper">
                    <div class="pui-content-wrapper__child">
                        <div class="pui-grid">
                            <div class="pui-grid__child pui-grid__child--size-12 pui-grid__child--size-12-s pui-grid__child--size-4-m pui-grid__child--offset-0 padding-same--24">
                                <p class="" v-if="fbQuestions.length > 1">{{ $store.getters.tText('question') }} {{ questionIndex }}/{{ fbQuestions.length }}</p>
                                <p class="mt-2">{{ currentQuestion.caption }}</p>
                                <!--
                                <p v-if="currentQuestion.question_type === 2" class="">
                                  {{ $store.getters.tText('feedbackPrivacy') }}
                                </p>-->
                            </div>
                        </div>
                    </div>
                    <div v-if="answerOptions.length > 0" class="pui-content-wrapper__child feedback-answerswrapper">
                        <div class="pui-grid">

                          <!--
                          <div class="pui-grid__child pui-grid__child&#45;&#45;size-12 pui-grid__child&#45;&#45;size-12-s pui-grid__child&#45;&#45;size-12-m pui-grid__child&#45;&#45;offset-0 padding-same&#45;&#45;24">
                            <p >{{ $store.getters.tText('feedbackOneChoice') }}</p>
                          </div>
                          -->

                            <span v-for="aO in answerOptions" :key="'answer'+ currentQuestion.objvalueid +'_'+ aO.index" class="">
                                  <svg @click="setAnswer(aO.index)" version="1.1" xmlns="http://www.w3.org/2000/svg" viewBox="0 12.705 512 486.59" x="0px" y="0px" xml:space="preserve" width="32px" height="32px" :fill="aO.index <= getIndexSelected ? ' #f39c12' : ''"><polygon points="256.814,12.705 317.205,198.566 512.631,198.566 354.529,313.435 414.918,499.295 256.814,384.427 98.713,499.295 159.102,313.435 1,198.566 196.426,198.566 "></polygon></svg>
                            </span>
                        </div>
                    </div>
                    <div v-if="currentQuestion.question_type === 2" class="pui-content-wrapper__child feedback-answerswrapper">
                        <div class="pui-grid">
                            <div class="pui-grid__child pui-grid__child--size-12 pui-grid__child--size-12-s pui-grid__child--size-12-m pui-grid__child--offset-0 padding-same--24">
                                <textarea rows="5" cols="20" v-model="questionAnswer"></textarea>
                            </div>
                        </div>
                    </div>
                </div>

                <div v-if="fbQuestions.length > 0" class="navwrapper">
                    <div class="pui-content-wrapper__child">
                        <div class="pui-grid">

                            <div v-if="prevQuestion" class="pui-grid__child pui-grid__child--size-12 pui-grid__child--size-12-s pui-grid__child--size-4-m pui-grid__child--offset-0 padding-same--24">
                              <router-link class="btn btn-primary btn btn-block" replace :to="{ path:$store.getters.selectedPage(parseInt(this.$route.params.pid,10)).link +'/'+ prevQuestion.objvalueid }">
                                {{ $store.getters.tText('prevQuestion') }}
                              </router-link>
                            </div>
                            <div v-if="!nextQuestion && canContinue && $route.name !== 'feedback'" class="pui-grid__child pui-grid__child--size-12 pui-grid__child--size-12-s pui-grid__child--size-4-m pui-grid__child--offset-0 padding-same--24">
                                <button type="button" @click="sendFeedbackAnswers" class="btn btn-primary btn btn-block" :disabled="sendTriggered">
                                  {{ $store.getters.tText('send') }}
                                </button>
                            </div>
                            <div v-if="nextQuestion && canContinue" class="pui-grid__child pui-grid__child--size-12 pui-grid__child--size-12-s pui-grid__child--size-4-m pui-grid__child--offset-0 padding-same--24">
                                <router-link class="btn btn-primary btn btn-block" replace :to="{ path:$store.getters.selectedPage(parseInt(this.$route.params.pid,10)).link +'/'+ nextQuestion.objvalueid }">
                                  {{ $route.name === 'feedback' ? $store.getters.tText('answerQuestions') : $store.getters.tText('nextQuestion') }}
                                </router-link>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section v-if="sendTriggered" class="b-standard-module mt-5 mb-5">
                <div class="pui-content-wrapper__child">
                    <div class="pui-grid">
                        <div class="pui-grid__child pui-grid__child--size-12 pui-grid__child--size-12-s pui-grid__child--size-12-m pui-grid__child--offset-0 padding-same--24">
                            <p>{{ $store.getters.tText('thankYouFeedback') }}</p>
                        </div>
                    </div>
                </div>
            </section>
            <section v-if="!sendTriggered && pageData.feedback_answered" class="b-standard-module mt-5 mb-5">
                <div class="pui-content-wrapper__child">
                    <div class="pui-grid">
                        <div class="pui-grid__child pui-grid__child--size-12 pui-grid__child--size-12-s pui-grid__child--size-12-m pui-grid__child--offset-0 padding-same--24">
                            <p>{{ $store.getters.tText('feedbackLeft') }}</p>
                        </div>
                    </div>
                </div>
            </section>
          </div>
        </div>
    </div>
</template>

<script>
import _ from 'lodash'
import db from '@general/db'

export default({
    name: "FeedbackQuestions",
    data() {
        return {
            sendTriggered:false,
            everythingLoaded:false,
            answers: Array
        }
    },
    created() {
        const vm = this
        if (this.$store.state.feedbackanswers.length === 0) {
            vm.everythingLoaded = true
        }
        this.$watch('$store.getters.sortedFeedbackQestions', nQs => {
            if (!this.everythingLoaded) {
                this.everythingLoaded = nQs.some( q => typeof q.answer !== 'undefined' )
            }
        })
        this.$store.dispatch('getFeedbackQuestions')
        this.$watch('sendTriggered', nst => {
            if (nst) {
                this.$nextTick(() => {
                    window.dispatchEvent(new Event('resize'))
                })
            }
        })
        this.$watch('$route.params.quid', () => {
            this.answers = Array
            this.sendTriggered = false
            window.scrollTo(0, 0)
        })
    },
    destroyed() {
        this.$store.commit('unloadFeedbackQuestions')
    },
    methods: {
        setAnswer(answer) {

          this.$store.commit('setFeedbackAnswer', {
            fbq: {
              objvalueid: this.currentQuestion.objvalueid,
              pid: parseInt(this.$route.params.pid,10),
            },
            fba: {answer}
          })

        },
        sendFeedbackAnswers() {
            if (this.sendTriggered === false) {
                this.sendTriggered = true
                this.$store.dispatch('authorizeSendFeedbackAnswers')
            }
        }
    },
    computed: {
        pageData() {
          return this.$store.getters.selectedPage( parseInt(this.$route.params.pid,10) )
        },
        fbQuestions() {
           return this.$store.getters.sortedFeedbackQestions
        },
        nextQuestion() {
            const questions = _.filter(this.fbQuestions, q => {
                if (q === _.last(this.fbQuestions) && q.allowed_to_send === false) {
                    // Letzte Frage erlauben, solange es nicht abgeschickt ist.
                    return true
                }
                return !q.answer
            })
            if (this.$route.name === 'feedback' && questions.length > 0) {
                return _.head(questions)
            } else if (this.currentQuestion) {
                const cQIndex = _.findIndex(questions, fbq => fbq === this.currentQuestion)
                if (questions[cQIndex] !== _.last(questions)) {
                    return questions[(cQIndex +1)]
                }
            }

            return null
        },
        canContinue() {
            return this.everythingLoaded && (!this.currentQuestion || this.currentQuestion.question_type === 1 || this.currentQuestion.question_type === 2)
        },
        prevQuestion() {
            const questions = this.fbQuestions
            if (this.$route.name === 'feedback' || questions.length < 1) {
                return null
            } else if (this.currentQuestion) {
                const cQIndex = _.findIndex(questions, fbq => fbq === this.currentQuestion)
                if (questions[cQIndex] !== _.head(questions)) {
                    return questions[(cQIndex -1)]
                }
            }

            return null
        },
        getIndexSelected() {
          const cQIndex = _.findIndex(this.answerOptions, fbq => fbq.selected === true)
          return cQIndex + 1;
        },
        currentQuestion() {
            if (this.$route.name !== 'feedbackquestion' || !this.$route.params || !this.$route.params.quid) {
                return null
            } else {
                return _.find(this.fbQuestions, fbq => fbq.objvalueid === parseInt(this.$route.params.quid,10))
            }
        },
        answerOptions() {
            if (!this.currentQuestion || this.currentQuestion.question_type === 2) {
                return []
            }


            return this.currentQuestion.rating_options
        },
        questionIndex() {
            if (!this.currentQuestion) {
                return ''
            }

            return _.findIndex(this.fbQuestions, fbq => fbq === this.currentQuestion) + 1
        },
        questionAnswer: {
            get() {
                return this.currentQuestion.answer
            },
            set(val) {
                this.setAnswer(val)
            },
        }
    }
});
</script>

<style scoped lang="scss">
    .padding-same--24 + .padding-same--24 {
        margin-top: 24px;
    }

    .b-standard-module {
        padding-bottom: 5px;
    }

    .navwrapper, .feedback-answerswrapper {
        margin-top: 48px;

        textarea {
            width:100%;
            font-family: inherit;
            font-size: inherit;
            padding: .75rem;
            height:8em;
            background-color: #fff;
            border: 1px solid #c8cacb;
            outline: none;
        }
    }

    .button-primary--red:not([disabled]):focus, .button-primary--red:not([disabled]):hover {
      background-color: #d5001c;
      border-color: #d5001c;
    }


</style>
