<template>
    <router-link :class="'homekachel '+ kachelsize" :style="kachelStyle" :to="{ path:$store.getters.linkToPage(kachel.objvalueid) }">
        <span class="pagename">{{ kachel.pagename }}</span>
        <WeatherEntry v-if="weatherEntry" type="home" :eW="weatherEntry" />
        <span class="kachel-icon" v-if="locationIcon">
            <svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="map-marker-alt" class="svg-inline--fa fa-map-marker-alt fa-w-12" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512"><path fill="currentColor" d="M172.268 501.67C26.97 291.031 0 269.413 0 192 0 85.961 85.961 0 192 0s192 85.961 192 192c0 77.413-26.97 99.031-172.268 309.67-9.535 13.774-29.93 13.773-39.464 0zM192 272c44.183 0 80-35.817 80-80s-35.817-80-80-80-80 35.817-80 80 35.817 80 80 80z"></path></svg>
        </span>
        <span class="kachel-icon" v-if="kachelIcon === 'list-alt'">
            <svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="list-ul" class="svg-inline--fa fa-list-ul fa-w-16" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path fill="currentColor" d="M48 48a48 48 0 1 0 48 48 48 48 0 0 0-48-48zm0 160a48 48 0 1 0 48 48 48 48 0 0 0-48-48zm0 160a48 48 0 1 0 48 48 48 48 0 0 0-48-48zm448 16H176a16 16 0 0 0-16 16v32a16 16 0 0 0 16 16h320a16 16 0 0 0 16-16v-32a16 16 0 0 0-16-16zm0-320H176a16 16 0 0 0-16 16v32a16 16 0 0 0 16 16h320a16 16 0 0 0 16-16V80a16 16 0 0 0-16-16zm0 160H176a16 16 0 0 0-16 16v32a16 16 0 0 0 16 16h320a16 16 0 0 0 16-16v-32a16 16 0 0 0-16-16z"></path></svg>
        </span>
        <span class="kachel-icon" v-if="kachelIcon === 'users'">
            <svg aria-hidden="true" focusable="false" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 640 512" class="fa-users"><path fill="currentColor" d="M544 224c44.2 0 80-35.8 80-80s-35.8-80-80-80-80 35.8-80 80 35.8 80 80 80zm0-128c26.5 0 48 21.5 48 48s-21.5 48-48 48-48-21.5-48-48 21.5-48 48-48zM320 256c61.9 0 112-50.1 112-112S381.9 32 320 32 208 82.1 208 144s50.1 112 112 112zm0-192c44.1 0 80 35.9 80 80s-35.9 80-80 80-80-35.9-80-80 35.9-80 80-80zm244 192h-40c-15.2 0-29.3 4.8-41.1 12.9 9.4 6.4 17.9 13.9 25.4 22.4 4.9-2.1 10.2-3.3 15.7-3.3h40c24.2 0 44 21.5 44 48 0 8.8 7.2 16 16 16s16-7.2 16-16c0-44.1-34.1-80-76-80zM96 224c44.2 0 80-35.8 80-80s-35.8-80-80-80-80 35.8-80 80 35.8 80 80 80zm0-128c26.5 0 48 21.5 48 48s-21.5 48-48 48-48-21.5-48-48 21.5-48 48-48zm304.1 180c-33.4 0-41.7 12-80.1 12-38.4 0-46.7-12-80.1-12-36.3 0-71.6 16.2-92.3 46.9-12.4 18.4-19.6 40.5-19.6 64.3V432c0 26.5 21.5 48 48 48h288c26.5 0 48-21.5 48-48v-44.8c0-23.8-7.2-45.9-19.6-64.3-20.7-30.7-56-46.9-92.3-46.9zM480 432c0 8.8-7.2 16-16 16H176c-8.8 0-16-7.2-16-16v-44.8c0-16.6 4.9-32.7 14.1-46.4 13.8-20.5 38.4-32.8 65.7-32.8 27.4 0 37.2 12 80.2 12s52.8-12 80.1-12c27.3 0 51.9 12.3 65.7 32.8 9.2 13.7 14.1 29.8 14.1 46.4V432zM157.1 268.9c-11.9-8.1-26-12.9-41.1-12.9H76c-41.9 0-76 35.9-76 80 0 8.8 7.2 16 16 16s16-7.2 16-16c0-26.5 19.8-48 44-48h40c5.5 0 10.8 1.2 15.7 3.3 7.5-8.5 16.1-16 25.4-22.4z" class=""></path></svg>
        </span>
        <span class="kachel-icon" v-if="kachelIcon === 'pagtime'">
         <svg aria-hidden="true" focusable="false" data-prefix="far" data-icon="clock" class="svg-inline--fa fa-clock fa-w-16" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path fill="currentColor" d="M256 8C119 8 8 119 8 256s111 248 248 248 248-111 248-248S393 8 256 8zm0 448c-110.5 0-200-89.5-200-200S145.5 56 256 56s200 89.5 200 200-89.5 200-200 200zm61.8-104.4l-84.9-61.7c-3.1-2.3-4.9-5.9-4.9-9.7V116c0-6.6 5.4-12 12-12h32c6.6 0 12 5.4 12 12v141.7l66.8 48.6c5.4 3.9 6.5 11.4 2.6 16.8L334.6 349c-3.9 5.3-11.4 6.5-16.8 2.6z"></path></svg>
        </span>
        <span class="kachel-icon pag-list" v-if="kachelIcon === 'pag-list'">
         <svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="list" class="svg-inline--fa fa-list fa-w-16" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path fill="currentColor" d="M80 368H16a16 16 0 0 0-16 16v64a16 16 0 0 0 16 16h64a16 16 0 0 0 16-16v-64a16 16 0 0 0-16-16zm0-320H16A16 16 0 0 0 0 64v64a16 16 0 0 0 16 16h64a16 16 0 0 0 16-16V64a16 16 0 0 0-16-16zm0 160H16a16 16 0 0 0-16 16v64a16 16 0 0 0 16 16h64a16 16 0 0 0 16-16v-64a16 16 0 0 0-16-16zm416 176H176a16 16 0 0 0-16 16v32a16 16 0 0 0 16 16h320a16 16 0 0 0 16-16v-32a16 16 0 0 0-16-16zm0-320H176a16 16 0 0 0-16 16v32a16 16 0 0 0 16 16h320a16 16 0 0 0 16-16V80a16 16 0 0 0-16-16zm0 160H176a16 16 0 0 0-16 16v32a16 16 0 0 0 16 16h320a16 16 0 0 0 16-16v-32a16 16 0 0 0-16-16z"></path></svg>
        </span>
        <span class="kachel-icon pag-video" v-if="kachelIcon === 'pag-video'">
          <svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="video" class="svg-inline--fa fa-video fa-w-18" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512"><path fill="currentColor" d="M336.2 64H47.8C21.4 64 0 85.4 0 111.8v288.4C0 426.6 21.4 448 47.8 448h288.4c26.4 0 47.8-21.4 47.8-47.8V111.8c0-26.4-21.4-47.8-47.8-47.8zm189.4 37.7L416 177.3v157.4l109.6 75.5c21.2 14.6 50.4-.3 50.4-25.8V127.5c0-25.4-29.1-40.4-50.4-25.8z"></path></svg>
        </span>
        
    </router-link>
</template>

<script>
import WeatherEntry from '@/components/WeatherEntry.vue'
import filterKachelColor from '@/filterKachelColor'
import TimeFlysIcon from "@/components/TimeFlysIcon.vue"
import Color from 'color'

export default({
    name: "HomeKachel",
    props: ['kachel'],
    components: { WeatherEntry, TimeFlysIcon },
    computed: {
        kachelsize() {
            let useSize = 6
            if (this.kachel.size > 0 && this.kachel.size < 13) {
                useSize = this.kachel.size
            }
            return 'k-'+ useSize
        },
        kachelStyle() {

          let color_kachel_default = '#008542'

          const colorsFromEvent = this.$store.getters.eventData.colors
          if (colorsFromEvent && colorsFromEvent.color_kachel_default) {
            color_kachel_default = colorsFromEvent.color_kachel_default
          }


          let bgcolor = this.kachel.color
          if (bgcolor === '' || bgcolor === null) {
            bgcolor = color_kachel_default
          } else {
            bgcolor = bgcolor.replace(/ /,'')
          }

          let imageAdd = ''
          if (this.kachel.image && this.kachel.image !== '') {
            imageAdd = 'background-image:url('+ this.kachel.image +');background-repeat:no-repeat;background-position:center;background-size:cover;';
          }
          return 'background-color:'+ bgcolor +';'+ imageAdd

        },
        weatherEntry() {
            if (this.kachel.hasOwnProperty('rptype') && this.kachel.rptype === 4) {
                // Wetter
                return this.$store.getters.nextWeatherEntry
            }
            return null
        },
        locationIcon() {
            return this.kachel.hasOwnProperty('rptype') && this.kachel.rptype === 3
        },
        kachelIcon() {
            return this.kachel.hasOwnProperty('icon') && this.kachel.icon !== '' ? this.kachel.icon : ''
        }
    },
    mounted() {
        this.setContrastColor()
        this.$watch('kachel.color', this.setContrastColor)
    },
    methods: {
        setContrastColor() {
            let bgcolorToCompare = this.kachel.color
            const colorFromEvent = this.$store.getters.eventData.colors
            if (bgcolorToCompare === '' || bgcolorToCompare === null) {
                bgcolorToCompare = colorFromEvent.color_kachel_default ? colorFromEvent.color_kachel_default : '#008542';
            }
            
            bgcolorToCompare = bgcolorToCompare.replace(/ /, '')

            const bgColorObj = Color(bgcolorToCompare)
            //const useContrast = colorFromEvent.event_color_contrast ? colorFromEvent.event_color_contrast : (bgColorObj.isDark() ? '#fff' : '#000')
            const useContrast = filterKachelColor(bgColorObj.isDark() ? '#fff' : '#000')

            if (colorFromEvent.event_color && colorFromEvent.event_color === bgcolorToCompare) {
                //this.$el.style.setProperty('--arrow-color', useContrast)
                this.$el.style.setProperty('--kachel-color', useContrast)
                return
            }

            this.$el.style.setProperty('--kachel-color', bgColorObj.isDark() ? '#fff' : '#000')
        }
    }
});
</script>

<style scoped lang="scss">
    @use "sass:math";
    @import "../../style/just_variables";

    .homekachel {
        position: relative;
        display: flex;
        overflow: hidden;
        padding:(math.div($grid-gutter-width, 2));
        color:var(--kachel-color);
        border:1px solid var(--group-color);

        flex-direction: column-reverse;
        justify-content:space-between;
        min-height:200px;
        

        span.pagename {
            font-size: 1.2rem;
            line-height: 2rem;
        }

        svg {
            overflow: visible;
        }
        
        .kachel-icon {
            display: inline-block;
            width:50%;
            padding:16px;
            svg {
                width:100%;
                max-width:64px;
                height:auto;

                path {
                    fill:var(--kachel-color);
                }
            }
        }
        
        
    }
    
    
</style>