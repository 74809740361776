<template>
    <div :class="'b-page-wrapper'+ pageClass">
        <PageHeader />
        <router-view v-if="!waitingForData" @pagemounted="mapMounted = true" />
        <div v-if="waitingForData" id="spinner">
            <Spinner v-if="$store.state.isOnline" />
            <div v-if="!$store.state.isOnline && !recheckingContent" style="padding:0 7.5%">{{ $store.getters.tText('notInOfflineStore') }}</div>
        </div>
        
        <transition name="jumpin">
            <span v-show="showJumper" id="topjumper" class="icon icon--arrow_up" @click="gotoTop"></span>
        </transition>
        
        <PageFooter />
    </div>
</template>

<script>
import PageHeader from '@/components/PageHeader.vue'
import Spinner from '@/components/Spinner.vue'
import PageFooter from '@/components/PageFooter.vue'
import moment from 'moment-timezone'
import Color from 'color'

export default({
    data() {
        return {
            mapMounted: false,
            showJumper: false,
            scrollTop: 0,
            recheckingContent: false
        }
    },
    name: "ContentPage",
    components: { PageHeader, Spinner, PageFooter },
    created() {
        if (this.$store.state.events.length < 1) {
            this.$store.dispatch('readEventData')
        }
        this.$watch('$route', (newRoute, oldRoute) => {
            if (oldRoute === undefined) {
                return
            }

            if (
                (
                    newRoute.hasOwnProperty('name') && 
                    newRoute.name !== 'singleimage' && newRoute.name !== 'singlevideo' && 
                    oldRoute.hasOwnProperty('name') &&
                    oldRoute.name !== 'singleimage' && oldRoute.name !== 'singlevideo' &&
                    newRoute.name !== oldRoute.name
                ) ||
                (
                    newRoute.params.hasOwnProperty('pid') &&
                    (
                        !oldRoute.params.hasOwnProperty('pid') || newRoute.params.pid !== oldRoute.params.pid
                    )
                )
            ) {
                // Nach dem Navigieren wieder nach oben scrollen, es sei denn, wir rufen ein Bild/Video in der Galerie auf.
                window.scrollTo(0, 0)
            }
        })
        this.triggerEventidUpdate(this.$route.params.eventid)
        this.pageIDUpdate()
        this.$watch('$route.params.eventid', this.triggerEventidUpdate)
        this.$watch('$route.params.pid', this.pageIDUpdate)
        this.$watch('$store.state.quotaExceeded', this.pageIDUpdate)
        window.addEventListener('scroll', this.setScrollOffset)
        this.$watch('scrollTop', (nPos, oPos) => {
            if (nPos < -10) {
                if (
                    this.$route.params.hasOwnProperty('pid') &&
                    !this.waitingForData &&
                    !this.$store.state.pagesLoadTriggered.some(pLT => pLT === parseInt(this.$route.params.cid ,10))
                ) {
                    this.$store.store.dispatch('getPageContent', parseInt(this.$route.params.cid ,10))
                }
            }
            
            if (nPos <= 20) {
                this.showJumper = false
            } else if (oPos > nPos) {
                this.showJumper = true
            } else {
                this.showJumper = false
            }
        })
        
        this.$watch('$store.state.tick', (test_moment) => {
            if (this.$store.state.isOnline) {
                const compareMoment = this.$store.getters.eventData.last_fetch
                if (typeof compareMoment === 'object' && compareMoment !== null) {
                    if (test_moment.isAfter(moment(compareMoment).add(2, 'minutes'))) {
                        this.$store.commit('queueUpdate')
                        this.$store.dispatch('getPages')
                        this.$store.dispatch('readLocations')
                        this.$store.dispatch('readWeatherData', this.$store.getters.eventData.weather)
                    }
                }
            }
        })


    },
    beforeUnmount() {
        window.removeEventListener('scroll', this.setScrollOffset)
    },
    methods: {
        pageIDUpdate(newPage, oldPage) {
            this.recheckingContent = false
            if (this.$route.params.hasOwnProperty('pid')) {
                if (this.$store.state.isOnline) {
                    this.$nextTick(() => {
                        const pageid = parseInt(this.$route.params.pid, 10)
                        const cpage = this.$store.getters.selectedPage(pageid)
                        if (cpage.content === null) {
                            this.recheckingContent = true
                            this.$store.dispatch('getPageContent', pageid)
                                .finally(() => {
                                    this.recheckingContent = false
                                })
                        }
                    })
                }
            }
        },
        triggerEventidUpdate(newValue, oldValue) {
            if (this.$store.state.eventid !== parseInt(newValue,10)) {
                this.$store.commit('gotoEvent', parseInt(newValue,10))
            }
            this.$store.dispatch('getPages', parseInt(newValue,10))
        },
        setScrollOffset() {
            this.scrollTop = window.scrollY
        },
        gotoTop() {
            window.scrollTo(0, 0)
        }
    },
    computed: {
        pageClass() {
            const waitingclass = this.waitingForData ? ' waiting':''
            
            if (this.$route.hasOwnProperty('name')) {
                return waitingclass + ' page-'+ this.$route.name
            }
            return waitingclass
        },
        waitingForData() {
            if (this.recheckingContent) {
                return true
            }

            if (this.$store.getters.eventData.timezone === null) {
                return true
            }
            
            if (this.$route.name && (this.$route.name === 'logouttoconfirm' || this.$route.name === 'logouttoconfirmnoevent' || this.$route.name === 'debug')) {
                return false
            }

            if (this.$route.name && this.$route.name === 'qrcode') {
                return this.$store.state.credentials.barcode === null || this.$store.getters.selectedPage( 'qrcode' ) === null
            }

            if (this.$route.name && (this.$route.name === 'feedback' || this.$route.name === 'feedbackquestion')) {
              return this.$store.state.feedbackquestions === null && this.$store.getters.selectedPage( 'feedback' ) === null
            }

            if (this.$route.name && this.$route.name === 'map' || this.$route.name === 'mapbounds') {
                if (this.mapMounted) {
                    return false
                }
                const mapPage = this.$store.getters.selectedPage( 'map' )
                return mapPage === null || typeof mapPage.meta !== 'object' || mapPage.meta === null || !mapPage.meta.hasOwnProperty('map')
            } else {
                this.mapMounted = false
            }

            const pageid = parseInt(this.$route.params.pid,10)
            
            if (this.$route.name && (this.$route.name === 'gallery' || this.$route.name === 'singleimage' || this.$route.name === 'singlevideo')) {
                if (!this.$route.params.hasOwnProperty('pid')) {
                    return true
                }
                const cpage = this.$store.getters.selectedPage( pageid )
                return cpage === null || typeof cpage.meta !== 'object' || cpage.meta === null || !cpage.meta.hasOwnProperty('gallery')
            }
            
            
            if (this.$route.params.hasOwnProperty('pid')) {
                const cpage = this.$store.getters.selectedPage( pageid )
                if (!cpage.hasOwnProperty('content') || cpage.content === null) {
                    // Noch kein Content. Gucken, ob wir wenigstens eine Unternavigation rendern können.
                    const subpages = this.$store.getters.navitems( pageid )
                    return !(subpages && subpages.length > 0)
                }
                return false
            }
            
            if (this.$route.name && this.$route.name === 'home') {
                return false
                //return this.$store.state.pages === null || this.$store.getters.kacheln.length < 1
            }
            
            if (this.$route.name && (this.$route.name === 'weather' || this.$route.name === 'weathersingleday')) {
                return this.$store.state.weatherData === null
            }
            
            return true
        }
    }
});
</script>

<style lang="scss">
    #topjumper {
        position: fixed;
        bottom:2rem;
        display: block;
        right:7.14286%;
        color:var(--highlight-color);
        cursor:pointer;

        &.jumpin-enter-active, &.jumpin-leave-active {
            transition: opacity .5s, bottom 1.5s;
        }
        &.jumpin-enter, &.jumpin-leave-to {
            opacity: 0;
            bottom:-2rem;
        }
    }

    .jumpin-enter-active, .jumpin-leave-active {
        transition: opacity .5s, bottom 1.5s;
    }
    .jumpin-enter, .jumpin-leave-to {
        opacity: 0;
        bottom:0;
    }
    
    #spinner {
        display: flex;
        justify-content:center;
        align-items:center;
        width:100%;
        min-height:600px;
    }
</style>