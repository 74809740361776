<template>
    <div>
        <div class="b-title-wrapper" data-lazy="true">
            <div class="b-title-headline-text">
                <h1>
                    <span>{{ pageData.pagename }}</span>
                </h1>
            </div>
        </div>
        <section class="row mt-5 mb-5">
            <div class="col-6 offset-3">
              <div class="qrcode" id="qrcode">
                <qrcode-vue :value="qrcodesvg" size="300" level="H" render-as="svg"/>
              </div>
            </div>
        </section>
        <section v-html="content"></section>
        <SubpageButtons />
    </div>
</template>

<script>
import SubpageButtons from '@/components/SubpageButtons.vue'
import QrcodeVue from 'qrcode.vue'

export default({
    name: "QRCode",
    data() {
        return {
          qrcodesvg: this.$store.state.credentials.barcode[this.$store.state.eventid],
        }
    },
    components: { SubpageButtons, QrcodeVue },
    computed: {
        pageData() {
            return this.$store.getters.selectedPage( 'qrcode' )
        },
        content() {
            let pageDataContentHTML = this.pageData.content

            if (typeof pageDataContentHTML !== "string") {
                return ''
            }

            return pageDataContentHTML.replace(/col-xs-(\d+)/g, 'col-$1')
        }
    },
    methods: {

    }
});
</script>

<style lang="scss">
    .qrcode svg {
        display: block;
        width:100%;
        height:auto;

    }
    
    .qrcode-realtext {
        padding:2em 0 0;
        text-align: center;
    }
</style>