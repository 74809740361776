<template>
    <div class="b-page-wrapper cookiedisclaimer" style="height: 72px;">
        <div id="cookie-notice" class="b-standard-module-wrapper b-linkblock-notice xxx1" style="">
            <div class="b-standard-module">
                {{ warningText }}
            </div>
        </div>
    </div>
</template>

<script>
export default({
    name: "MobileDevicePrompt",
    computed: {
        warningText() {
            return this.$store.state.language === 'de' ?
                'Bitte öffnen Sie diese Seite auf einem mobilen Endgerät.':
                'Please open this page on a mobile device.'
        }
    }
});
</script>

<style scoped lang="scss">
    .b-linkblock-notice {
        display: block;
    }
</style>