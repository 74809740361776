<template>
    <div class="form-group">
        <label :for="genID">{{ caption }}</label>
        <input ref="cinput" class="form-control" :id="genID" :value="value" :type="inputType" @change="updateInput" @keypress.enter="useToSubmit" v-on:focus="setFocus(true)">
    </div>
</template>

<script>
import _ from "lodash"

export default({
    name: "FormElement",
    props: ['caption', 'type', 'value', 'withSubmit'],
    data() {
        return {
            formFocused:false
        }
    },
    computed: {
        genID() {
            return _.uniqueId('input_')
        },
        inputType() {
            if (this.type === 'Email') {
                return 'email'
            }
            return this.type === 'Password' ? 'password' : 'text'
        },
        valueSet() {
            return (typeof this.value === 'string' && this.value !== '') || (typeof this.value === 'object' && this.value !== null && this.value.length > 0)
        }
    },
    mounted() {
        const vm = this
        this.$el.querySelector('input').addEventListener('blur', vm.setBlur)
    },
    beforeUnmount() {
        const vm = this
        this.$el.querySelector('input').removeEventListener('blur', vm.setBlur)
    },
    methods: {
        setBlur() {
            this.setFocus(false)
        },
        setFocus(newfocus) {
            this.formFocused = newfocus
            if (this.formFocused) {
                this.$el.querySelector('input').focus({ preventScroll:true })
            }
        },
        useToSubmit() {
            this.updateInput()
            this.$emit('submit-clicked')
        },
        updateInput() {
            this.$emit('input', this.$refs.cinput.value)
        }
    }
});
</script>

<style scoped>

</style>