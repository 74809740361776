<template>
  <div>
    <div id="eventdata" class="homekachel k-12">
      <div class="b-title-wrapper">
        <div class="b-title-headline-text">
          <h1 class="pui-text--align-center pui-text--wrap" v-html="eventName">
          </h1>
        </div>
      </div>
    </div>
    <CountDown />
    <div class="kachelcontainer" ref="kachelcontainer">
      <HomeKachel v-for="k in $store.getters.kacheln" :kachel="k" :key="'kachel_'+ k.objvalueid"/>
    </div>
  </div>
</template>

<script>
import HomeKachel from '@/components/HomeKachel.vue'
import CountDown from '@/components/CountDown.vue'
import _ from 'lodash'

export default({
  name: 'home',
  components: { HomeKachel, CountDown },
  mounted() {
    const vm = this
    vm.setHomeSize()
    window.addEventListener('resize', vm.setHomeSize)
  },
  beforeUnmount() {
    const vm = this
    window.removeEventListener('resize', vm.setHomeSize)
  },
  computed: {
    eventName() {
      let teventname = this.$store.getters.eventData.name
      return teventname
    }
  },
  methods: {
    setHomeSize() {
      const h = Math.max(document.documentElement.clientHeight, window.innerHeight || 0)
      this.$refs.kachelcontainer.style.setProperty('--content-height', (h - 200) +'px')
    }
  }
});
</script>

<style scoped lang="scss">
@use "sass:math";
  @import "../../style/just_variables";

  .kachelcontainer {
    --content-height:200px;
    display: flex;
    flex-wrap:wrap;
    align-items:stretch;
    align-content:stretch;
    min-height:var(--content-height);
    @include make-row();
  }

  $col-count: 12;
  @for $colnum from 1 through $col-count {
      .k-#{$colnum} {
        flex-basis:percentage(math.div($colnum , $col-count));
      }
  }
  
  #eventdata {
    padding:0;
    background-color: #eff0f1;
    align-self:flex-start;
    
    h1 {
      white-space: normal;
    }
  }
  
  .fresh-message-wrap {
    position:fixed;
    top:0;
    left:0;
    width:100%;
    height:100%;
    background:rgba(0,0,0,.75);
    display:flex;
    align-items:center;
    align-content:center;
    z-index: 1000;
    
    .hide-icon {
      position: fixed;
      right:7%;
      top:1em;
      color:#fff;
      z-index: 1001;

      span {
        display: block;
        font-size:1.5rem;
        height:auto;
      }
    }
    
    .b-standard-module {
      background-color: #fff;
      width:100%;
    }
    
  }
</style>